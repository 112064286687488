import React from 'react'
import { Panel, Form, Col, FormControl, Button } from 'react-bootstrap'
import Select from 'react-select'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  return (
    <Panel collapsible expanded={isShow} className="no-border">
      <Form horizontal>
        <Col md={3}>
          <FormControl
            type="text"
            value={filter.mainPaxName || ''}
            onChange={(e) =>
              onFilterChange('mainPaxName', e.currentTarget.value)
            }
            placeholder="Passenger Name"
          />
        </Col>
        <Col md={3}>
          <FormControl
            type="text"
            value={filter.productName || ''}
            onChange={(e) =>
              onFilterChange('productName', e.currentTarget.value)
            }
            placeholder="Product"
          />
        </Col>
        <Col md={3}>
          <Select
            value={filter.status}
            onChange={(value) => onFilterChange('status', value)}
            placeholder="Status"
            simpleValue
            options={statusOptions}
          />
        </Col>
        <Col md={2}>
          <Button onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash"></span> Clear Filter
          </Button>
        </Col>
      </Form>
    </Panel>
  )
}

const statusOptions = [
  { label: 'TENTATIVE', value: 'tentative' },
  { label: 'CONFIRMED', value: 'confirmed' },
  { label: 'COMPLETED', value: 'completed' },
  { label: 'NOSHOW', value: 'noShow' },
  { label: 'CANCELED', value: 'canceled' },
]
