import React from 'react'
import { connect } from 'react-redux'
import { fetchReservationCounts } from '../../actions/remote_api'

export default (query) => (Component) => {
  return reservationCountConnect(
    class ReservationCountWrapper extends React.Component {
      componentDidMount() {
        this.props.fetchReservationCounts({
          ...query,
          warning: true,
        })
      }

      render() {
        const { totalCount, warningCount } = this.props
        return (
          <Component
            {...this.props}
            totalCount={totalCount}
            warningCount={warningCount}
          />
        )
      }
    },
  )
}

const reservationCountConnect = connect(
  ({ fetch: { reservation, reservationCount } }) => ({
    totalCount: reservation ? reservation.totalCount : undefined,
    warningCount:
      reservationCount && reservationCount.list
        ? reservationCount.list.count
        : undefined,
  }),
  { fetchReservationCounts },
)
