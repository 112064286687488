import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { Icon, horizontalCenter } from 'react-icons-kit'
import { iosArrowBack } from 'react-icons-kit/ionicons'
import { compose } from 'recompose'

const withStyled = (Button) => styled(Button).attrs({
  bsStyle: 'link',
})`
  padding-left: 0px;
`

const withHorizontalCenter = (Button) =>
  horizontalCenter((props) => <Button {...props}>{props.children}</Button>)

const VerticalCenteredIcon = styled(Icon)`
  > svg {
    margin-top: 4px;
  }
`

const BackButton = compose(withStyled, withHorizontalCenter)(Button)

export default (props) => (
  <BackButton {...props}>
    <VerticalCenteredIcon icon={iosArrowBack} size={24} />
    {props.children}
  </BackButton>
)
