import React from 'react'
import { FormGroup, InputGroup, FormControl, Glyphicon } from 'react-bootstrap'
import styled from 'styled-components'
import AirlineCheckInTypeItem from './AirlineCheckInTypeItem'

export default ({
  airlines = [],
  onAirlineSelect,
  filterText,
  onFilterTextChange,
}) => (
  <Container>
    <div className="col-md-12 tab-search-field">
      <FormGroup>
        <InputGroup>
          <FormControl
            type="text"
            value={filterText}
            onChange={(e) => onFilterTextChange(e.currentTarget.value)}
          />
          <InputGroup.Addon>
            <Glyphicon glyph="search" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    </div>
    <div className="scroll-container">
      {renderAirlineItems(airlines, onAirlineSelect)}
    </div>
  </Container>
)

const renderAirlineItems = (airlines, onAirlineSelect) => {
  if (airlines.length <= 0) return <NoData>No Data</NoData>
  return airlines.map((airline) => (
    <AirlineCheckInTypeItem
      key={airline.id}
      airline={airline}
      onClick={onAirlineSelect}
    />
  ))
}

const Container = styled.div`
  height: 100%;
`

const NoData = styled.div.attrs({ className: 'row row-alirline' })`
  text-align: center;
`
