import React from 'react'
import CheckInScene from './CheckInScene'
import {
  createServiceTxn,
  updateServiceTxn,
  fetchDefaults,
  fetchPaxCountByStation,
} from '../../actions/remote_api'
import {
  submitCheckIn,
  fetchCheckIn,
  clearCheckIn,
} from '../../actions/checkin'
import { connect } from 'react-redux'
import { info } from '../../storage'
import { toastr } from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n'
import { get } from 'lodash'
import { showExtraChargeModalIfNeeded } from '../../components/ExtraCharge/index'
import { debounce } from 'lodash'

function errorMessageDetail(errors) {
  if (get(errors, 'serviceInfo.duration')) return 'No privilege'
  else if (get(errors, 'serviceInfo.productPrice'))
    return get(errors, 'serviceInfo.productPrice')

  return 'please check invalid fields'
}

class CheckInSceneContainer extends React.Component {
  state = { isSubmiting: false }

  constructor(props) {
    super(props)
    this.handleSubmit = debounce(this.handleSubmit, 500)
  }

  handleSubmit = (callback) => {
    this.props.submitCheckIn()
    if (this.props.hasErrors) {
      toastr.error('Cannot check in', errorMessageDetail(this.props.errors))
      return
    }

    const { serviceId, stationId } = info()
    const serviceTxn = {
      ...this.props.serviceTxn,
      serviceId,
      stationId: stationId,
    }

    const submitAction = () => {
      const { isCreate, createServiceTxn, updateServiceTxn } = this.props
      let submit = isCreate ? createServiceTxn : updateServiceTxn
      this.setState({ isSubmiting: true })
      submit(serviceTxn, (response) => {
        if (response.ok) {
          this.serviceTxnDidSave()
          if (callback) callback()
        } else {
          this.serviceTxnDidSaveFail()
        }
        this.setState({ isSubmiting: false })
      })
    }

    showExtraChargeModalIfNeeded(serviceTxn, submitAction)
  }

  serviceTxnDidSave = () => {
    toastr.success(I18n.t('toastr.saveSuccess'))
    this.resetToModeCreate()
    this.props.fetchPaxCountByStation(info().stationId)
  }

  serviceTxnDidSaveFail = () => {
    toastr.error(I18n.t('toastr.saveFail'))
  }

  resetToModeCreate = () => {
    this.props.history.push('checkin')
    this.props.clearCheckIn()
  }

  handleFormClear = () => {
    this.props.clearCheckIn()
  }

  componentDidMount() {
    const { state } = this.props.location

    if (state && state.serviceTxnId) {
      this.props.fetchCheckIn(state.serviceTxnId)
    }
    this.props.fetchDefaults()
  }

  componentWillUnmount() {
    this.props.clearCheckIn()
  }

  render() {
    const _info = info()
    if (_info)
      return (
        <CheckInScene
          {..._info}
          onSubmit={this.handleSubmit}
          isCreate={this.props.isCreate}
          onClearForm={this.handleFormClear}
          isSubmiting={this.state.isSubmiting}
        />
      )
    else return null
  }
}

export default connect(
  ({ checkin: { serviceTxn, isCreate, hasErrors, errors } }) => ({
    serviceTxn,
    isCreate,
    hasErrors,
    errors,
  }),
  {
    createServiceTxn,
    updateServiceTxn,
    submitCheckIn,
    fetchCheckIn,
    clearCheckIn,
    fetchDefaults,
    fetchPaxCountByStation,
  },
)(CheckInSceneContainer)
