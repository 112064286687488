import React from 'react'
import { Panel } from 'react-bootstrap'

export default ({ classToServiceMapping, onClick }) => (
  <Panel onClick={onClick}>
    <h1>{classToServiceMapping.privilegeClass}</h1>
    {classToServiceMapping.duration && (
      <span>Duration: {classToServiceMapping.duration}</span>
    )}
  </Panel>
)
