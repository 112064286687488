import traverse from 'traverse'
import { isEmpty } from 'lodash'
import { validateWith, required, date } from '../form'
import {
  validateProductPrice,
  isProductRequired,
} from '../modules/productPrice'

const errors = (serviceTxn) => {
  const errors = {}
  errors.checkInType = validateWith(serviceTxn.checkInType, [required])

  const serviceInfo = serviceTxn.serviceInfo
  errors.customerLastName = validateWith(serviceTxn.customerLastName, [
    required,
  ])
  errors.customerFirstName = validateWith(serviceTxn.customerFirstName, [
    required,
  ])
  errors.flightNumber = validateWith(serviceTxn.flightNumber, [required])
  errors.seatNumber = validateWith(serviceTxn.seatNumber, [required])
  errors.travelClass = validateWith(serviceTxn.travelClass, [required])
  errors.productId = isProductRequired(serviceTxn)
    ? validateWith(serviceTxn.productId, [required])
    : undefined
  errors.serviceInfo = {
    inTime: validateWith(serviceInfo ? serviceInfo.inTime : undefined, [
      required,
      date,
    ]),
    duration: validateWith(serviceInfo ? serviceInfo.duration : undefined, [
      required,
    ]),
    productPrice: validateProductPrice(serviceTxn),
  }
  return errors
}

const hasErrors = (errors) =>
  traverse(errors).reduce(function (hasErrors, value) {
    if (this.isLeaf) hasErrors |= !isEmpty(value)
    return hasErrors
  }, false) === 1

const validate = (serviceTxn) => {
  const _errors = errors(serviceTxn)
  return {
    errors: _errors,
    hasErrors: hasErrors(_errors),
  }
}

export default validate
