import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { info } from '../../storage'
import ReservationCheckInTypeContainer from './ReservationCheckInTypeContainer'
import AirlineCheckInTypeContainer from './AirlineCheckInTypeContainer'
import WalkinCheckInTypeContainer from './WalkinCheckInTypeContainer'
import { withCheckInType } from './CheckInTypeContainer'

const CheckInTypePanelContainer = ({
  pristine,
  selectCheckInType,
  selectReservation,
}) => {
  const handleAirlineSelect = (airline) => {
    selectCheckInType({
      checkInType: 'airline',
      airlineCheckInInfo: { airline },
      agentCheckInInfo: undefined,
      walkinCheckInInfo: undefined,
    })
  }

  const handleCashSelect = () =>
    selectCheckInType({
      checkInType: 'walkin',
      airlineCheckInInfo: undefined,
      agentCheckInInfo: undefined,
      walkinCheckInInfo: { type: 'cash' },
    })

  const handleCreditCardSelect = () =>
    selectCheckInType({
      checkInType: 'walkin',
      airlineCheckInInfo: undefined,
      agentCheckInInfo: undefined,
      walkinCheckInInfo: { type: 'creditCard' },
    })

  const handleCardSelect = (card) =>
    selectCheckInType({
      checkInType: 'walkin',
      airlineCheckInInfo: undefined,
      agentCheckInInfo: undefined,
      walkinCheckInInfo: { type: 'card', card },
    })

  const handleOtherSelect = () =>
    selectCheckInType({
      checkInType: 'walkin',
      walkinCheckInInfo: { type: 'others' },
    })

  const handleReservationSelect = (reservation) => {
    selectReservation(reservation, info().serviceId)
  }

  return (
    <CheckInTypePanel
      onAirlineSelect={handleAirlineSelect}
      onReservationSelect={handleReservationSelect}
      onCashSelect={handleCashSelect}
      onCreditCardSelect={handleCreditCardSelect}
      onCardSelect={handleCardSelect}
      onOthersSelect={handleOtherSelect}
    />
  )
}

const CheckInTypePanel = ({
  onReservationSelect,
  onAirlineSelect,
  onCashSelect,
  onCreditCardSelect,
  onCardSelect,
  onOthersSelect,
}) => (
  <div className="row" style={{ height: '100%' }}>
    <Tabs defaultActiveKey={1} id="checkin-type" className="tabs-kiosk">
      <Tab
        eventKey={1}
        title={I18n.t('checkInScene.checkInType.tabs.reservation')}
      >
        <ReservationCheckInTypeContainer
          onReservationSelect={onReservationSelect}
        />
      </Tab>
      <Tab eventKey={2} title={I18n.t('checkInScene.checkInType.tabs.airline')}>
        <AirlineCheckInTypeContainer onAirlineSelect={onAirlineSelect} />
      </Tab>
      <Tab eventKey={3} title={I18n.t('checkInScene.checkInType.tabs.walkin')}>
        <WalkinCheckInTypeContainer
          onCashSelect={onCashSelect}
          onCreditCardSelect={onCreditCardSelect}
          onCardSelect={onCardSelect}
          onOthersSelect={onOthersSelect}
        />
      </Tab>
    </Tabs>
  </div>
)

// export default connect(
//   ({ checkin }) => ({ pristine: checkin.pristine }),
//   { selectCheckInType, selectReservation }
// )(CheckInTypePanelContainer)

export default withCheckInType(CheckInTypePanelContainer)
