import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalTitle } from 'react-bootstrap'
import styled from 'styled-components'
import BarcodeLogo from '../../img/barcode.gif'

export default ({ isShow, onHide }) => (
  <Modal show={isShow} onHide={onHide} backdrop="static">
    <ModalHeader closeButton>
      <ModalTitle>Scan Code</ModalTitle>
    </ModalHeader>
    <ModalBody className="text-center">
      <BarCodeImg src={BarcodeLogo} alt="logo" />
      <h2>
        <strong>Waiting for code scaning</strong>
      </h2>
    </ModalBody>
  </Modal>
)

const BarCodeImg = styled.img`
  width: 50%;
`
