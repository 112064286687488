import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppBar from '../../components/AppBar'
import ServiceMenuItem from './ServiceMenuItem'
import { fetchServiceStations } from '../../actions/remote_api'
import storage from '../../storage'

class MenuScene extends React.Component {
  componentDidMount() {
    const { fetchServiceStations } = this.props
    fetchServiceStations()
  }

  handleServiceClick = (serviceStation) => {
    const hasOneStation = serviceStation.stations.length === 1
    const station = serviceStation.stations[0]
    const serviceId = station ? station.serviceId : undefined
    storage.clearInfo()
    storage.saveInfo(
      hasOneStation && station && serviceId
        ? {
            serviceId,
            serviceName: serviceStation.name,
            serviceClass: station.serviceClass,
            serviceDisplayName: station.serviceDisplayName,
            stationId: station.id,
            stationName: station.name,
          }
        : {
            serviceId,
            serviceName: serviceStation.name,
          },
    )
    this.props.history.push(hasOneStation ? '/checkin' : '/stations')
  }

  render() {
    const { serviceStations } = this.props
    return (
      <div>
        <AppBar header="INTERNAL MANAGEMENT SYSTEMS" />

        <div className="kiosk-container">
          <div className="serviceItem-container">
            {(serviceStations || []).map((service) => (
              <ServiceMenuItem
                key={service.name}
                service={service}
                onServiceClick={this.handleServiceClick}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    ({ fetch }) => ({
      serviceStations: fetch.serviceStation
        ? fetch.serviceStation.list
        : undefined,
    }),
    { fetchServiceStations },
  )(MenuScene),
)
