import React from 'react'
import { Navbar, Button, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import {
  userAdd,
  documentIcon,
  check,
  hdd,
  comments,
  boxBlank,
  userRemove,
} from 'react-icons-kit/metrize'
import { Link, withRouter } from 'react-router-dom'
import { checkIfAnyGrantedByGivenAuth } from '../../modules/authService'
import { connect } from 'react-redux'
import LogoutContainer from '../../components/auth/LogoutContainer'
import { clearInfo } from '../../storage'
import KioskCapacityLabel from '../KioskCapacityLabel'
import styled from 'styled-components'

class AppBar extends React.Component {
  handleLogout = () => {
    clearInfo()
  }

  render() {
    const { auth, header, subHeader, hasBtnGroup = false } = this.props

    return (
      <Row>
        <Navbar fixedTop collapseOnSelect className="app-bar">
          <Navbar.Header>
            <Link to="/">
              <LogoImage src={require('../../img/corla-logo.png')} alt="logo" />
            </Link>

            <Navbar.Toggle style={{ display: 'none' }} />
          </Navbar.Header>

          <div className="navbar-text-panel">
            <div className="text-container">
              <div className="main">{header}</div>
              {subHeader && (
                <div className="sub">
                  {subHeader.length > 70
                    ? `${subHeader.slice(0, 70)} ...`
                    : subHeader}
                </div>
              )}

              {hasBtnGroup && (
                <CapacityLabelContainer>
                  <KioskCapacityLabel />
                </CapacityLabelContainer>
              )}
            </div>
          </div>

          {hasBtnGroup ? (
            <NavBarBtnGroupFull auth={auth} handleLogout={this.handleLogout} />
          ) : (
            <NavBarBtnGroupLoginOnly
              auth={auth}
              handleLogout={this.handleLogout}
            />
          )}
        </Navbar>
      </Row>
    )
  }
}

const renderUserAuthenInfoButton = (auth, handleLogout) => {
  if (
    auth.isPrepared &&
    !checkIfAnyGrantedByGivenAuth(auth, ['user', 'report'])
  )
    return (
      <Link to="/login">
        <Button
          className="navbar-btn-withIcon"
          bsStyle="primary"
          bsSize="large"
        >
          <Icon icon={userAdd} size={50} className="kiosk-icon" />
          Login
        </Button>
      </Link>
    )
  else if (
    auth.isPrepared &&
    checkIfAnyGrantedByGivenAuth(auth, ['user', 'report'])
  )
    return (
      <LogoutContainer onLogout={handleLogout}>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip">
              <strong>Logout</strong>
            </Tooltip>
          }
        >
          <Button
            className="navbar-btn-withIcon"
            bsStyle="primary"
            bsSize="large"
          >
            <Icon icon={userRemove} size={50} className="kiosk-icon" />
            Hi, {auth.user.name || '-'}
          </Button>
        </OverlayTrigger>
      </LogoutContainer>
    )
  return null
}

export const NavBarBtnGroupFull = withRouter(
  ({ auth, location, handleLogout }) => {
    return (
      <div className="navbar-icon-panel">
        <div className="navbar-icon">
          <Link to="/checkin">
            <Button
              className={
                location.pathname === '/checkin'
                  ? 'navbar-btn-withIcon active'
                  : 'navbar-btn-withIcon'
              }
              bsStyle="primary"
              bsSize="large"
            >
              <Icon icon={check} size={50} className="kiosk-icon" />
              Check-in
            </Button>
          </Link>
        </div>
        <div className="navbar-icon">
          <Link to="/station-report">
            <Button
              className={
                location.pathname === '/station-report'
                  ? 'navbar-btn-withIcon active'
                  : 'navbar-btn-withIcon'
              }
              bsStyle="primary"
              bsSize="large"
            >
              <Icon icon={documentIcon} size={50} className="kiosk-icon" />
              Station Report
            </Button>
          </Link>
        </div>
        <div className="navbar-icon">
          <Link to="/summary-report">
            <Button
              className={
                location.pathname.startsWith('/summary-report')
                  ? 'navbar-btn-withIcon active'
                  : 'navbar-btn-withIcon'
              }
              bsStyle="primary"
              bsSize="large"
            >
              <Icon icon={hdd} size={50} className="kiosk-icon" />
              Summary Report
            </Button>
          </Link>
        </div>
        <div className="navbar-icon">
          <Link to="/assignment">
            <Button
              className={
                location.pathname.startsWith('/assignment')
                  ? 'navbar-btn-withIcon active'
                  : 'navbar-btn-withIcon'
              }
              bsStyle="primary"
              bsSize="large"
            >
              <Icon icon={comments} size={50} className="kiosk-icon" />
              Assignment
            </Button>
          </Link>
        </div>
        <div className="navbar-icon">
          <Link to="/mark-final">
            <Button
              className={
                location.pathname === '/mark-final'
                  ? 'navbar-btn-withIcon active'
                  : 'navbar-btn-withIcon'
              }
              bsStyle="primary"
              bsSize="large"
            >
              <Icon icon={boxBlank} size={50} className="kiosk-icon" />
              Quick Check
            </Button>
          </Link>
        </div>
        <div className="navbar-btn-container">
          {renderUserAuthenInfoButton(auth, handleLogout)}
        </div>
      </div>
    )
  },
)

export const NavBarBtnGroupLoginOnly = ({ auth, handleLogout }) => {
  return (
    <div className="navbar-icon-panel">
      <div className="navbar-icon"></div>
      <div className="navbar-icon"></div>
      <div className="navbar-icon"></div>
      <div className="navbar-icon"></div>
      <div className="navbar-icon"></div>
      <div className="navbar-btn-container">
        {renderUserAuthenInfoButton(auth, handleLogout)}
      </div>
    </div>
  )
}

const CapacityLabelContainer = styled.div`
  position: absolute;
  top: 14%;
  right: 1%;
`

const LogoImage = styled.img`
  max-height: 60px;
  margin-top: -5px;
  width: 50%;
`

export default connect(({ auth }) => ({ auth }))(AppBar)
