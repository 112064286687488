import React from 'react'

export const ExtraChargeModalBody = ({
  serviceTxn: { reservation, serviceInfo },
}) => (
  <table className="table">
    <thead>
      <tr>
        <td></td>
        <td className="text-center">Reservation</td>
        <td className="text-center">Service</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Adult</td>
        <td className="text-center">{reservation.guestPaxAdult}</td>
        <td className="text-center">{serviceInfo.guestPaxAdult}</td>
      </tr>
      <tr>
        <td>Child</td>
        <td className="text-center">{reservation.guestPaxChild}</td>
        <td className="text-center">{serviceInfo.guestPaxChild}</td>
      </tr>
      <tr>
        <td>Infant</td>
        <td className="text-center">{reservation.guestPaxInfant}</td>
        <td className="text-center">{serviceInfo.guestPaxInfant}</td>
      </tr>
      <tr>
        <td>Number of Luggage</td>
        <td className="text-center">{reservation.luggageAmount}</td>
        <td className="text-center">{serviceInfo.luggageAmount}</td>
      </tr>
    </tbody>
  </table>
)
