module.exports = {
  RTButton: {
    button: '_1T6gd',
    rippleWrapper: '_3rch8',
    squared: 'x_Fgj',
    icon: 't6_L8',
    solid: 'HUuyg',
    raised: '_33l7R _1T6gd x_Fgj HUuyg',
    flat: '_2JPw5 _1T6gd x_Fgj',
    floating: '_2U_a5 _1T6gd HUuyg',
    mini: '_2for7',
    toggle: '_3xRDd _1T6gd',
    primary: '_29RI9',
    accent: 'mWBhu',
    neutral: '_3ViU3',
    inverse: '_1kgaQ',
  },
  RTRipple: {
    rippleWrapper: 'cNe4x',
    ripple: '_1zJTi',
    rippleRestarting: '_3wQEe',
    rippleActive: '_1eRuo',
  },
  RTDatePicker: {
    input: '_2cfff',
    disabled: '_3PC3_',
    inputElement: 'xD_FE',
    header: 'yZhG0',
    year: 'uhnZd',
    date: '_3wNVO',
    calendarWrapper: '_2zBGJ',
    yearsDisplay: 'SV0v0',
    monthsDisplay: '_2-Gxw',
    dialog: 'lOLkB',
    button: '_50klV',
    calendar: '_1juUq',
    prev: 'PGGSb',
    next: '_3wsgQ',
    title: '_37fII',
    years: '_1yrr_',
    active: '_2jTt2',
    week: '_1wp1K',
    days: '_1JzI7',
    day: '_349-w',
    month: '_1mUHN',
    slideRightEnter: '_1tJhJ',
    slideRightLeave: '_1pz4X',
    slideRightEnterActive: 'fAUzE',
    slideRightLeaveActive: '_33v8X',
    slideLeftEnter: 'mOLI0',
    slideLeftLeave: '_2uLcH',
    slideLeftEnterActive: '_3gdF0',
    slideLeftLeaveActive: '_1lAUa',
  },
  RTInput: {
    input: '_2dBwA',
    withIcon: '_2uwUs',
    icon: '_2H9rJ',
    inputElement: '_2WvFs',
    bar: 'fT1WI',
    label: '_3NjcG',
    fixed: '_1ANNN',
    required: 'HMiz1',
    hint: '_1yQnr',
    filled: '_3QmiH',
    error: '_1p4yC',
    counter: '_2dI1B',
    disabled: '_2sOZC',
    errored: 'ZsBmg',
    hidden: '_3Wr_7',
  },
  RTDialog: {
    wrapper: '_3niLM',
    dialog: '_17Ijy',
    active: '_1594s',
    small: '_3AQIo',
    normal: '_3wffD',
    large: '_3L5Sk',
    fullscreen: '_2F8R7',
    title: '_27QqA',
    body: '_33od4',
    navigation: 'kA5VY',
    button: '_2r12z',
  },
  RTOverlay: { overlay: '_1kTMH', active: '_3vAcK' },
}
