import React, { Component } from 'react'
import './App.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css'
import 'react-select/dist/react-select.css'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/react-toolbox/theme.css'
import './styles/ionicons/css/ionicons.min.css'
import './custom-bootstrap.css'
import './less-styles.css'
import ThemeProvider from 'react-toolbox/lib/ThemeProvider'
import theme from './assets/react-toolbox/theme'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import ReduxToastr from 'react-redux-toastr'

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Router>
            <AppRouter />
          </Router>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
        </div>
      </ThemeProvider>
    )
  }
}

export default App
