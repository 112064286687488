import React from 'react'
import ReassignJobAssignmentModal from './ReassignJobAssignmentModal'

export default class ReassignJobAssignmentModalContainer extends React.Component {
  state = {
    targetStaff: undefined,
  }

  handleStaffSelect = (targetStaff) => {
    this.setState({
      ...this.state,
      targetStaff,
    })
  }

  handleSubmit = () => {
    const { onSubmit, currentAssignmentInfo } = this.props
    const { reservation, jobAssignment } = currentAssignmentInfo
    onSubmit({
      reservation: reservation,
      jobAssignment: jobAssignment,
      targetStaff: this.state.targetStaff,
    })
    this.clearData()
  }

  clearData = () => {
    this.setState({
      targetStaff: undefined,
    })
  }

  onClose = () => {
    this.clearData()
    this.props.onClose()
  }

  isSubmitValid = () => {
    return this.state.targetStaff
  }

  render() {
    const { reservation, jobAssignment } = this.props.currentAssignmentInfo
    return (
      <ReassignJobAssignmentModal
        isShow={this.props.isShow}
        onHide={this.onClose}
        reservation={reservation}
        jobAssignment={jobAssignment}
        targetStaff={this.state.targetStaff}
        onStaffSelect={this.handleStaffSelect}
        isSubmitValid={this.isSubmitValid()}
        onSubmit={this.handleSubmit}
      />
    )
  }
}
