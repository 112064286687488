import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import {
  selectCheckInType,
  selectReservation,
  updateServiceInfo,
  loadServiceTxn,
} from '../../actions/checkin'
import { info } from '../../storage'

const connectCheckInType = connect(
  ({ checkin: { isPristine, isCreate }, fetch: { defaults } }) => ({
    isPristine,
    isCreate,
    defaultDurations:
      defaults && defaults.list ? defaults.list.durations : undefined,
  }),
  {
    selectCheckInType,
    selectReservation,
    updateServiceInfo,
    loadServiceTxn,
  },
)

export const withCheckInType = (Component) =>
  connectCheckInType(withCheckInTypeContainer(Component))

export const withCheckInTypeContainer = (Component) =>
  class CheckInTypeContainer extends React.Component {
    state = {
      showSaveDialog: false,
    }

    handleSelectCheckInType = (checkInType) => {
      const { isPristine, isCreate } = this.props

      if (!isPristine && !isCreate)
        this.setState({
          showSaveDialog: true,
          handleAfterSave: () => this.selectCheckInType(checkInType),
        })
      else this.selectCheckInType(checkInType)
    }

    selectCheckInType = (checkInType) => {
      const { serviceName, serviceClass } = info()

      this.props.selectCheckInType(checkInType)
      this.props.updateServiceInfo(
        serviceName,
        serviceClass,
        this.props.defaultDurations,
      )
    }

    handleSelectReservation = (reservation, serviceId) => {
      if (!this.props.isPristine)
        this.setState({
          showSaveDialog: true,
          handleAfterSave: () => this.selectReservation(reservation, serviceId),
        })
      else this.selectReservation(reservation, serviceId)
    }

    selectReservation = (reservation, serviceId) => {
      this.props.selectReservation(
        reservation,
        serviceId,
        this.updateServiceInfoIfNeeded,
      )
    }

    handleLoadServiceTxn = (serviceTxn) => {
      if (!this.props.isPristine)
        this.setState({
          showSaveDialog: true,
          handleAfterSave: () => this.loadServiceTxn(serviceTxn),
        })
      else this.loadServiceTxn(serviceTxn)
    }

    loadServiceTxn = (serviceTxn) => {
      this.props.loadServiceTxn(serviceTxn)
      this.updateServiceInfoIfNeeded(serviceTxn)
    }

    updateServiceInfoIfNeeded = (serviceTxn) => {
      const { serviceName, serviceClass } = info()
      if (
        serviceTxn &&
        serviceTxn.serviceInfo &&
        serviceTxn.serviceInfo.duration
      )
        return
      this.props.updateServiceInfo(
        serviceName,
        serviceClass,
        this.props.defaultDurations,
      )
    }

    handleSave = () => {
      if (this.props.onSubmit && this.state.handleAfterSave) {
        this.props.onSubmit(this.state.handleAfterSave)
        this.setState({ showSaveDialog: false })
      }
    }

    handleDontSave = () => {
      if (this.state.handleAfterSave) {
        this.state.handleAfterSave()
        this.setState({ showSaveDialog: false })
      }
    }

    handleHideSaveDialog = () => this.setState({ showSaveDialog: false })

    render() {
      return (
        <div style={{ height: 'inherit' }}>
          <Component
            {...this.props}
            isPristine={this.props.isPristine}
            selectCheckInType={this.handleSelectCheckInType}
            selectReservation={this.handleSelectReservation}
            loadServiceTxn={this.handleLoadServiceTxn}
          />

          <SaveDialog
            isShow={this.state.showSaveDialog}
            onHide={this.handleHideSaveDialog}
            onSave={this.handleSave}
            onDontSave={this.handleDontSave}
          />
        </div>
      )
    }
  }

const SaveDialog = ({ isShow, onHide, onDontSave, onSave }) => (
  <Modal show={isShow} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
        <b>{I18n.t('checkInScene.saveDialog.title')}</b>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <h5>{I18n.t('checkInScene.saveDialog.description')}</h5>
    </Modal.Body>

    <Modal.Footer>
      <Button bsSize="lg" onClick={onHide}>
        {I18n.t('action.cancel')}
      </Button>
      <Button bsSize="lg" onClick={onDontSave} bsStyle="primary">
        {I18n.t('action.dontSave')}
      </Button>
      <Button bsSize="lg" onClick={onSave} bsStyle="primary">
        {I18n.t('action.save')}
      </Button>
    </Modal.Footer>
  </Modal>
)
