import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import styled from 'styled-components'

const ButtonGroupWithMargin = styled(ButtonGroup)`
  margin-right: 20px;
`
export default ({ label, data, dataStyle }) => (
  <ButtonGroupWithMargin>
    <Button>{label}</Button>
    <Button bsStyle={dataStyle}>{data !== undefined ? data : '-'}</Button>
  </ButtonGroupWithMargin>
)
