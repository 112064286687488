import React from 'react'
import ServiceTxnListView from './ServiceTxnListView'
import { connect } from 'react-redux'

class ServiceTxnListViewContainer extends React.Component {
  render() {
    return (
      <ServiceTxnListView
        serviceTxns={this.props.serviceTxns}
        onServiceTxnSelect={this.props.onServiceTxnSelect}
      />
    )
  }
}

export default connect(({ fetch: { serviceTxn } }) => ({
  serviceTxns: serviceTxn && serviceTxn.list ? serviceTxn.list : undefined,
}))(ServiceTxnListViewContainer)
