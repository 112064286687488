import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

import { ConfirmModal } from './ConfirmModal'
import { ServiceTxnCancelAction } from './ServiceTxnCancelAction'

export const ServiceTxnCancelConfirmModal = ({
  serviceTxn,
  serviceTxnDidConfirm,
  children,
}) => (
  <ServiceTxnCancelAction serviceTxn={serviceTxn}>
    {(cancelServiceTxn) => (
      <ConfirmModal>
        {({ isShow, showModal, onHide }) => (
          <div>
            {children(showModal)}

            <Modal
              className="top-reduce-modal"
              bsSize="sm"
              show={isShow}
              onHide={onHide}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <b>{I18n.t('cancelServiceTxnModal.header')}</b>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <h3>{I18n.t('cancelServiceTxnModal.detail')}</h3>
              </Modal.Body>

              <Modal.Footer>
                <Button bsSize="lg" onClick={onHide} bsStyle="default">
                  {I18n.t('action.close')}
                </Button>
                <Button
                  bsSize="lg"
                  onClick={cancelServiceTxn(() => {
                    onHide()
                    if (serviceTxnDidConfirm) serviceTxnDidConfirm()
                  })}
                  bsStyle="danger"
                >
                  {I18n.t('action.cancel')}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </ConfirmModal>
    )}
  </ServiceTxnCancelAction>
)
