import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { checkmark } from 'react-icons-kit/ionicons'

export default ({ onClick }) => (
  <Button bsStyle="primary" bsSize="lg" onClick={onClick}>
    <Icon icon={checkmark} /> Checkout
  </Button>
)
