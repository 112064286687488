import React from 'react'
import { isEmpty, debounce } from 'lodash'
import PropTypes from 'prop-types'
import ComplexValueSelect from './ComplexValueSelect'

class SearchSelect extends React.Component {
  state = {
    hasValue: false,
    searchText: '',
  }

  constructor(props) {
    super(props)
    this.searchOptions = debounce(this.searchOptions, 300)
    this.clearOptions = debounce(this.clearOptions, 300)
  }

  componentDidMount() {
    const {
      fetchOptions,
      defaultValueQuery,
      initialValue,
      clearOptions,
      selectId,
      preFetch,
    } = this.props

    if (clearOptions) clearOptions('list', { fetchId: selectId })

    if (!isEmpty(initialValue)) {
      fetchOptions(defaultValueQuery(initialValue), { fetchId: selectId })
    } else if (!this.isSearch() || preFetch) {
      fetchOptions({}, { fetchId: selectId })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchOptions, defaultValueQuery, initialValue, selectId } =
      this.props
    if (
      nextProps.initialValue !== initialValue &&
      !isEmpty(nextProps.initialValue)
    ) {
      fetchOptions(defaultValueQuery(nextProps.initialValue), {
        fetchId: selectId,
      })
    }
  }

  searchOptions = () => {
    const { fetchOptions, createQuery, selectId } = this.props
    const { searchText } = this.state
    if (!isEmpty(searchText))
      fetchOptions(createQuery(searchText), { fetchId: selectId })
  }

  clearOptions = () => {
    const { clearOptions, selectId } = this.props
    const { searchText, hasValue } = this.state
    if (isEmpty(searchText) && !hasValue && clearOptions)
      clearOptions('list', { fetchId: selectId })
  }

  handleInputChange = (value) => {
    if (!this.isSearch()) return

    this.setState(
      {
        hasValue: false,
        searchText: value,
      },
      this.searchOptions,
    )
  }

  handleChange = (optionOrValue) => {
    this.props.onChange(optionOrValue)
    this.setState(
      {
        ...this.state,
        hasValue: !isEmpty(optionOrValue),
      },
      // () => {
      //   if (this.isSearch()) this.clearOptions()
      // },
    )
  }

  handleBlur = (e) => {
    e.preventDefault() // handle in complatible between redux-form and react-select
    if (this.props.onBlur) this.props.onBlur()
  }

  isSearch = () => this.props.fetchType === 'search'

  render() {
    return (
      <ComplexValueSelect
        {...this.props}
        onBlur={this.handleBlur}
        onInputChange={this.handleInputChange}
        onBlurResetsInput={true}
        options={this.props.options}
        onChange={this.handleChange}
        filterOption={this.isSearch() ? () => true : undefined}
        placeholder={'Type to Search ' + (this.props.placeholder || '...')}
      />
    )
  }
}

SearchSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  fetchOptions: PropTypes.func,
  clearOptions: PropTypes.func,
  createQuery: PropTypes.func,
  defaultValueQuery: PropTypes.func,
  preFetch: PropTypes.bool,
  fetchType: PropTypes.oneOf(['search', 'select']),
}

SearchSelect.defaultProps = {
  preFetch: false,
  fetchType: 'search',
}

export default SearchSelect
