import React from 'react'
import {
  ListGroup,
  ListGroupItem,
  Badge,
  ButtonGroup,
  Button,
  Col,
  Row,
} from 'react-bootstrap'
import styled from 'styled-components'

export default class ReservationTableJobAssignmentColumn extends React.Component {
  createHandleAssignClick = (jobAssignment) => () => {
    this.props.onAssignClick(this.props.reservation, jobAssignment)
  }

  createHandleAcceptClick = (jobAssignment) => () => {
    this.props.onAcceptClick(this.props.reservation, jobAssignment)
  }

  render() {
    const { jobAssignments } = this.props
    return (
      <ListGroup>
        {jobAssignments &&
          jobAssignments.map((jobAssignment) => (
            <ReservationTableJobAssignmentColumnItem
              key={jobAssignment._id}
              firstName={jobAssignment.staff.firstName}
              lastName={jobAssignment.staff.lastName}
              displayName={jobAssignment.service.displayName}
              status={jobAssignment.status}
              onAssignClick={this.createHandleAssignClick(jobAssignment)}
              onAcceptClick={this.createHandleAcceptClick(jobAssignment)}
            />
          ))}
      </ListGroup>
    )
  }
}

const ReservationTableJobAssignmentColumnItem = ({
  firstName,
  lastName,
  displayName,
  status,
  onAssignClick,
  onAcceptClick,
}) => {
  return (
    <ListGroupItem>
      <Row>
        <Col xs={8}>
          <NameLabel>
            {firstName} {lastName} <br />
          </NameLabel>
          <ServiceLabel>
            <strong>{displayName}</strong>
          </ServiceLabel>
        </Col>
        <Col xs={4}>
          <Badge>{status.toUpperCase()}</Badge>
          <br />
          <ButtonGroup>
            <Button bsStyle="warning" onClick={onAssignClick}>
              <span className="glyphicon glyphicon-refresh"></span>
            </Button>
            {status === 'pending' && (
              <Button bsStyle="success" onClick={onAcceptClick}>
                <span className="glyphicon glyphicon-ok"></span>
              </Button>
            )}
          </ButtonGroup>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

const NameLabel = styled.span`
  font-size: 16px;
`

const ServiceLabel = styled.span`
  font-size: 14px;
`
