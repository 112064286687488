import React from 'react'
import StaffSelectListViewItem from './StaffSelectListViewItem'
import { ListGroup, Panel } from 'react-bootstrap'
import styled from 'styled-components'
export default ({ staffs, createStaffClickHandler, selectedStaff }) => {
  if (staffs === undefined) return renderEmptyState()

  if (staffs.length === 0) return renderNoStaffFound()

  return (
    <ResultPanel>
      {renderStaffList(staffs, createStaffClickHandler, selectedStaff)}
    </ResultPanel>
  )
}

const renderEmptyState = () => (
  <SearchPanel>
    <GreyText>Search for staff to assign</GreyText>
  </SearchPanel>
)

const renderNoStaffFound = () => <SearchPanel>NO STAFF FOUND</SearchPanel>

const renderStaffList = (staffs, createStaffClickHandler, selectedStaff) => {
  return staffs.map((staff) => (
    <StaffSelectListViewItem
      key={staff._id}
      staff={staff}
      onClick={createStaffClickHandler(staff)}
      isSelected={selectedStaff && selectedStaff._id === staff._id}
    />
  ))
}

const SearchPanel = styled(Panel)`
  font-size: 16px;
  text-align: center;
  height: 32vh;
`
const ResultPanel = styled(ListGroup)`
  font-size: 16px;
  text-align: center;
  height: 32vh;
  overflow: auto;
`
const GreyText = styled.p`
  color: #999999;
`
