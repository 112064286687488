import React from 'react'

export default ({ reservation, onClick }) => (
  <div
    className="row row-reservation"
    style={{ marginBottom: '3%' }}
    onClick={() => onClick(reservation)}
  >
    <div className="col-sm-12 btn btn-reservation btn-large">
      <div className="col-sm-4 main-text">{reservation.confirmationNumber}</div>
      <div className="col-sm-8 text-left">
        <p className="paxName">{reservation.mainPax.fullName}</p>
        <p className="flightNumber">{reservation.flightNumber}</p>
      </div>
    </div>
  </div>
)
