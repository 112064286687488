import React from 'react'
import { connect } from 'react-redux'
import { fetchStaffs } from '../../actions/remote_api'
import StaffSelectListView from './StaffSelectListView'

class StaffSelectListViewContainer extends React.Component {
  state = {
    selectedStaff: undefined,
  }

  createStaffClickHandler = (staff) => () => {
    this.setState({
      selectedStaff: staff,
    })

    if (this.props.onStaffSelect) this.props.onStaffSelect(staff)
  }

  render() {
    return (
      <StaffSelectListView
        staffs={this.props.staffs}
        createStaffClickHandler={this.createStaffClickHandler}
        selectedStaff={this.state.selectedStaff}
      />
    )
  }
}

export default connect(
  ({ fetch: { staff } }) => ({
    staffs: staff && staff.list ? staff.list : undefined,
  }),
  { fetchStaffs },
)(StaffSelectListViewContainer)
