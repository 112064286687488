import React from 'react'
import { Row, Col, Panel } from 'react-bootstrap'
import styled from 'styled-components'

export default ({ inTime, limitTime, outTime }) => (
  <CheckInFormInfoPanel>
    <Row>
      <Col md={4}>
        START TIME
        <br />
        <TimeLabel>{inTime || '-'}</TimeLabel>
      </Col>
      <Col md={4}>
        END TIME
        <br />
        <TimeLabel>{limitTime || '-'}</TimeLabel>
      </Col>
      <Col md={4}>
        OUT TIME
        <br />
        <TimeLabel>{outTime || '-'}</TimeLabel>
      </Col>
    </Row>
  </CheckInFormInfoPanel>
)

const CheckInFormInfoPanel = styled(Panel)`
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.4);
`

const TimeLabel = styled.strong`
  font-size: 20px;
`
