import { toastr } from 'react-redux-toastr'

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type) {
      if (action.type.endsWith('FAILURE')) {
        toastr.error('Operation Fail', 'Fail to connect to the service.')
        console.log(action.err)
      }
    }

    return next(action)
  }
