import React from 'react'
import ReservationTableContainer from '../../components/ReservationTable'
import { connect } from 'react-redux'
import { fetchReservations, clearReservation } from '../../actions/remote_api'
import { toastr } from 'react-redux-toastr'

class AssignmentPendingTableContainer extends React.Component {
  fetchInstances = (params) => {
    toastr.info('Loading')
    this.props.fetchReservations({
      ...params,
      pending: true,
      createByKiosk: false,
      daily: true,
    })
  }

  clearInstances = () => {
    this.props.clearReservation('list')
  }

  render() {
    return (
      <ReservationTableContainer
        fetchInstances={this.fetchInstances}
        clearInstances={this.clearInstances}
        isCustomerSatisfactionShow={false}
        {...this.props}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    instances: fetch.reservation ? fetch.reservation.list : undefined,
    totalCount: fetch.reservation ? fetch.reservation.totalCount : undefined,
  }),
  { fetchReservations, clearReservation },
)(AssignmentPendingTableContainer)
