let code
let scanSuccessDelegate
let scanCancelDelegate

export const startScan = (onScanSuccess, onScanCancel) => {
  clearCode()
  scanSuccessDelegate = onScanSuccess
  scanCancelDelegate = onScanCancel
  document.addEventListener('keydown', scanListener)
}

export const stopScan = () => {
  document.removeEventListener('keydown', scanListener)
}

const scanListener = (keyboardEvent) => {
  if (keyboardEvent.key === 'Enter') {
    scanWillSuccess()
  } else if (keyboardEvent.key === 'ESC') {
    scanWillCancel()
  } else if (keyboardEvent.key) appendKey(keyboardEvent.key)
}

const appendKey = (key) => {
  if (key.length === 1) code += key
}

const scanWillSuccess = () => {
  if (scanSuccessDelegate) scanSuccessDelegate(getCode())
  clearCode()
}

const scanWillCancel = () => {
  stopScan()
  if (scanCancelDelegate) scanCancelDelegate()
}

export const getCode = () => code || undefined

export const clearCode = () => {
  code = ''
}

clearCode()
