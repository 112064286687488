import React from 'react'
import { Link } from 'react-router-dom'

export default ({ serviceTxn, children }) => (
  <Link
    to={{ pathname: '/checkin', state: { serviceTxnId: serviceTxn.id } }}
    style={{ textDecoration: 'underline' }}
  >
    {children}
  </Link>
)
