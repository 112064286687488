import React from 'react'
import ExtraPrivilegeMenu from './ExtraPrivilegeMenu'
import GuestPrivilegeModalContainer from './GuestPrivilegeModalContainer'
import {
  useVoucherPrivilege,
  useComplimentaryPrivilege,
  clearExtraPrivilege,
  updateServiceInfo,
} from '../../actions/checkin'
import { connect } from 'react-redux'
import { info } from '../../storage'

const toggleAccordian = (isOpen) => (state) => ({
  ...state,
  isOpen: isOpen !== undefined ? isOpen : !state.isOpen,
})

class ExtraPrivilegeMenuContainer extends React.Component {
  state = {
    isOpen: false,
    guestPrivilegeModal: {
      isShow: false,
    },
  }

  componentDidMount() {
    this.voucherPrivileges = this.createVoucherPrivileges()
    this.complimentaryPrivileges = this.createComplimentaryPrivileges()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isCreate) {
      this.setState(toggleAccordian(false))
    }
  }

  togglePanel = () => {
    this.setState(toggleAccordian())
  }

  createUseVoucherPrivilege = (duration) => () => {
    this.props.useVoucherPrivilege(duration)
  }

  createUseComplimentaryPrivilege = (duration) => () => {
    this.props.useComplimentaryPrivilege(duration)
  }

  createVoucherPrivileges = () => [
    {
      duration: 0,
      useFn: this.createUseVoucherPrivilege(0),
    },
    {
      duration: 2.5,
      useFn: this.createUseVoucherPrivilege(2.5),
    },
    {
      duration: 3,
      useFn: this.createUseVoucherPrivilege(3),
    },
  ]

  createComplimentaryPrivileges = () => [
    {
      duration: 0,
      useFn: this.createUseComplimentaryPrivilege(0),
    },
    {
      duration: 2.5,
      useFn: this.createUseComplimentaryPrivilege(2.5),
    },
  ]

  handleUseGuestPrivilegeClick = () => {
    this.setState((state) => ({
      ...state,
      guestPrivilegeModal: {
        isShow: true,
      },
    }))
  }

  handleGuestPrivilegeModalHide = () => {
    this.setState((state) => ({
      ...state,
      guestPrivilegeModal: {
        isShow: false,
      },
    }))
  }

  handleClearExtraPrivilege = () => {
    const { serviceName, serviceClass } = info()
    this.props.clearExtraPrivilege()
    this.props.updateServiceInfo(
      serviceName,
      serviceClass,
      this.props.defaultDurations,
    )
  }

  render() {
    return (
      <div>
        <ExtraPrivilegeMenu
          extraPrivileges={this.props.extraPrivileges}
          voucherPrivileges={this.voucherPrivileges}
          complimentaryPrivileges={this.complimentaryPrivileges}
          onUseGuestPrivilegeClick={this.handleUseGuestPrivilegeClick}
          onClearExtraPrivilege={this.handleClearExtraPrivilege}
          guestOf={this.props.guestOf}
          duration={this.props.duration}
          onTogglePanel={this.togglePanel}
          isOpen={this.state.isOpen}
        />
        <GuestPrivilegeModalContainer
          isShow={this.state.guestPrivilegeModal.isShow}
          onHide={this.handleGuestPrivilegeModalHide}
        />
      </div>
    )
  }
}

export default connect(
  ({ checkin, fetch: { defaults } }) => ({
    extraPrivileges:
      checkin && checkin.serviceTxn && checkin.serviceTxn.extraPrivileges
        ? checkin.serviceTxn.extraPrivileges
        : undefined,
    duration:
      checkin &&
      checkin.serviceTxn &&
      checkin.serviceTxn.serviceInfo &&
      checkin.serviceTxn.serviceInfo.duration
        ? checkin.serviceTxn.serviceInfo.duration
        : undefined,
    guestOf:
      checkin && checkin.serviceTxn && checkin.serviceTxn.guestOf
        ? checkin.serviceTxn.guestOf
        : undefined,
    defaultDurations:
      defaults && defaults.list ? defaults.list.durations : undefined,
    isCreate: checkin ? checkin.isCreate : true,
  }),
  {
    useVoucherPrivilege,
    useComplimentaryPrivilege,
    clearExtraPrivilege,
    updateServiceInfo,
  },
)(ExtraPrivilegeMenuContainer)
