import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import ActiveServiceTxnPanel from './ActiveServiceTxnPanel'
import CheckedoutServiceTxnPanel from './CheckedoutServiceTxnPanel'

export default class StationReportTabContainer extends React.Component {
  state = {
    refresh: {
      tab1: false,
      tab2: false,
    },
  }

  refreshTab = (key) => {
    this.setState({
      ...this.state,
      refresh: {
        ...this.state.refresh,
        [key]: true,
      },
    })
  }

  createRefreshTabCompleteHandler = (key) => () => {
    this.setState({
      ...this.state,
      refresh: {
        ...this.state.refresh,
        [key]: false,
      },
    })
  }

  handleSelect = (key) => {
    this.refreshTab(key)
  }

  render() {
    return (
      <Tabs
        defaultActiveKey={'tab1'}
        onSelect={this.handleSelect}
        id="station-report-tab"
        className="station-report-tab"
      >
        <Tab eventKey="tab1" title="Active Check-in">
          <ActiveServiceTxnPanel
            isRefresh={this.state.refresh.tab1}
            onRefreshTabComplete={this.createRefreshTabCompleteHandler('tab1')}
          />
        </Tab>
        <Tab eventKey="tab2" title="Checked out">
          <CheckedoutServiceTxnPanel
            isRefresh={this.state.refresh.tab2}
            onRefreshTabComplete={this.createRefreshTabCompleteHandler('tab2')}
          />
        </Tab>
      </Tabs>
    )
  }
}
