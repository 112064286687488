import moment from 'moment'
import _ from 'lodash'

export const transformServiceTxns = (serviceTxns = []) =>
  serviceTxns
    .map((s) => ({
      ...s,
      stateCode: stateCode(s),
      serviceInfo: {
        ...s.serviceInfo,
        issuedOutTime: getIssuedOutTime(s),
        isLate: isLate(s),
        overTime: overTime(s),
      },
    }))
    .sort((a, b) => {
      const stateCodeDiff = a.stateCode - b.stateCode
      if (stateCodeDiff !== 0) return stateCodeDiff
      return moment(b.serviceInfo.inTime).isBefore(moment(a.serviceInfo.inTime))
    })

export const getIssuedOutTime = (serviceTxn) => {
  if (
    !serviceTxn.serviceInfo ||
    !serviceTxn.serviceInfo.duration ||
    !serviceTxn.serviceInfo.inTime
  )
    return undefined
  const inTime = moment(serviceTxn.serviceInfo.inTime)
  const {
    serviceInfo: { duration, extraDuration = 0 },
  } = serviceTxn
  const amountDurationInHours = duration + extraDuration
  return inTime.add({ hours: amountDurationInHours })
}

export const isLate = (serviceTxn) => {
  if (!serviceTxn.serviceInfo.outTime) return false
  if (serviceTxn.serviceType !== 'lounge') return false
  const issuedOutTime = getIssuedOutTime(serviceTxn)
  return issuedOutTime.isBefore(serviceTxn.serviceInfo.outTime)
}

export const stateCode = (serviceTxn) => {
  if (!serviceTxn.serviceInfo || !serviceTxn.serviceInfo.duration) return 0 // undetermined
  if (serviceTxn.serviceInfo.outTime) return 3 // normal

  const issuedOutTime = getIssuedOutTime(serviceTxn)
  const diffMinutes = issuedOutTime.diff(moment(), 'minutes')
  if (diffMinutes > 15) {
    return 3 // normal
  } else if (diffMinutes > 0) {
    return 2 // warning
  } else {
    return 1 // danger
  }
}

export const overTime = (serviceTxn) => {
  if (!serviceTxn.serviceInfo.duration) return 0 // undetermined
  const issuedOutTime = getIssuedOutTime(serviceTxn)
  var duration = moment.duration(new moment().diff(issuedOutTime))

  if (serviceTxn.serviceInfo.outTime)
    duration = moment.duration(
      serviceTxn.serviceInfo.outTime.diff(issuedOutTime),
    )

  var overHour =
    duration.minutes() > 15 ? duration.hours() + 1 : duration.hours()

  return overHour < 0 ? 0 : overHour
}

const extendPeriod = 1 // hour

export const extendDuration = (serviceTxn, duration) => {
  const prevExtraDuration =
    serviceTxn.serviceInfo && serviceTxn.serviceInfo.extraDuration
      ? serviceTxn.serviceInfo.extraDuration
      : 0

  const durationInHours = duration
    ? moment.duration(duration).asHours()
    : extendPeriod

  return {
    ...serviceTxn,
    serviceInfo: {
      ...serviceTxn.serviceInfo,
      extraDuration: prevExtraDuration + durationInHours,
    },
  }
}

export const countPax = (serviceTxn) =>
  Number(serviceTxn.guestPaxAdult || 0) +
  Number(serviceTxn.guestPaxChild || 0) +
  Number(serviceTxn.guestPaxInfant || 0)

export const isCancelable = (serviceTxn) =>
  _.get(serviceTxn, 'reservation.createByKiosk')
