import React from 'react'

export default ({ serviceTxn = {} }) => {
  switch (serviceTxn.checkInType) {
    case 'airline':
      return (
        <div>
          <h5>Airline</h5>
          <h5>
            <b>{serviceTxn.airlineCheckInInfo.airline.name}</b>
          </h5>
        </div>
      )
    case 'agent':
      return (
        <div>
          <h5>Agent</h5>
          <h5>
            <b>{serviceTxn.agentCheckInInfo.agent.name}</b>
          </h5>
        </div>
      )
    case 'walkin':
      if (serviceTxn.walkinCheckInInfo.type === 'cash') return <div>Cash</div>
      else if (serviceTxn.walkinCheckInInfo.type === 'card')
        return renderCard(serviceTxn.walkinCheckInInfo.card)
      else return <div>{serviceTxn.walkinCheckInInfo.type}</div>
    default:
      return null
  }
}

const renderCard = (card) => (
  <div>
    <h5>Card</h5>
    <h5>
      <b>
        {card.name} - {card.privilegeClass}
      </b>
    </h5>
  </div>
)
