import React from 'react'
import { ButtonGroup, ButtonToolbar, Button, Glyphicon } from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import { makeDetailLink } from '../table'
import { isEmpty, omitBy, debounce } from 'lodash'

export default (Component, TableFilterPanel, tableKey = '_id') =>
  (props) => (
    <TableConfig
      {...props}
      component={Component}
      tableFilterPanel={TableFilterPanel}
      tableKey={tableKey}
    />
  )

class TableConfig extends React.Component {
  state = {
    isShowFilterPanel: false,
  }

  toggleFilterPanel = () => {
    this.setState({
      ...this.state,
      isShowFilterPanel: !this.state.isShowFilterPanel,
    })
  }

  isFiltering = () =>
    !isEmpty(omitBy(this.props.filter, (value) => isEmpty(value)))

  render() {
    const {
      component: Component,
      tableFilterPanel: TableFilterPanel,
      tableKey,
      instances,
      onAdd,
      onEdit,
      btnGroup,
      hasBtnGroup = true,
      totalCount,
      currentPage,
      onPageChange,
      sizePerPage,
      onSortChange,
      onOpenDetailPopup,
      onConfirmDelete,
      ...props
    } = this.props

    const defaultOptions = {
      btnGroup: hasBtnGroup ? btnGroup : undefined,
      page: currentPage,
      sizePerPage: sizePerPage,
      sizePerPageList: sizePerPageList,
      onPageChange,
      onSortChange,
    }
    const defaultSettings = {
      data: instances,
      selectRow: { mode: 'checkbox' },
      fetchInfo: { dataTotalSize: totalCount },
      insertRow: false,
      deleteRow: false,
      tableContainerClass: 'table-kiosk',
      remote: true,
      pagination: true,
      keyField: '_id',
    }

    return (
      <Component
        {...props}
        instances={instances}
        onAdd={onAdd}
        onEdit={onEdit}
        onOpenDetailPopup={onOpenDetailPopup}
        detailLink={
          onOpenDetailPopup ? makeDetailLink(onOpenDetailPopup) : undefined
        }
        editButton={createEditButton(onEdit)}
        defaultOptions={defaultOptions}
        defaultSettings={defaultSettings}
        renderToolbar={renderToolbar(
          this.state.isShowFilterPanel,
          this.toggleFilterPanel,
          this.isFiltering(),
        )}
        isShowFilterPanel={this.state.isShowFilterPanel}
        onToggleFilterPanel={this.toggleFilterPanel}
        renderFilterPanel={renderFilterPanel(TableFilterPanel, {
          isShow: this.state.isShowFilterPanel,
          filter: this.props.filter,
          onClearFilter: this.props.onClearFilter,
          onFilterChange: this.props.onFilterChange,
        })}
      />
    )
  }
}

// const makeConfirmDeleteHandler = (tableKey, onConfirmDelete, instances) => keyList =>
//   onConfirmDelete(instances.filter(i => keyList.some(k => k === i[tableKey])))

// const createButtonGroup = onAdd => props => {
//   return (
//     <RBTButtonGroup sizeClass='btn-group-md'>
//       <InsertButton btnText={` ${I18n.t('action.add').toUpperCase()}`} onClick={onAdd} />
//       {props.deleteBtn}
//     </RBTButtonGroup>
//   );
// }

const createEditButton = (onEdit) => (cell, row) => (
  <EditButton onClick={() => onEdit(row._id)} />
)

const EditButton = ({ onClick }) => (
  <button className="btn btn-info" onClick={onClick}>
    <i className="glyphicon glyphicon-edit"></i> Edit
  </button>
)

const renderToolbar = (isShowFilterPanel, onToggleFilterPanel, isFiltering) => {
  return (
    <ButtonToolbar className="pull-right">
      <ButtonGroup>
        <Button
          onClick={onToggleFilterPanel}
          className={isFiltering && 'btn-info'}
        >
          <Glyphicon glyph="filter" /> {I18n.t('action.filter')}{' '}
          <Glyphicon
            glyph={isShowFilterPanel ? 'triangle-bottom' : 'triangle-top'}
          />
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  )
}

const renderFilterPanel = (TableFilterPanel, props) =>
  TableFilterPanel ? <TableFilterPanel {...props} /> : null

const withDataManager = (component) => (props) => (
  <TableDataManagerContainer {...props} paginatedComponent={component} />
)

const sizePerPageList = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
]

class TableDataManagerContainer extends React.Component {
  state = {
    currentPage: this.props.startPage || 1,
    sizePerPage: this.props.sizePerPage || 5,
    sort: undefined,
    order: undefined,
    filter: {},
  }

  constructor(props) {
    super(props)
    this.handleDateChange = debounce(this.handleDateChange, 350)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isRefetchItems) {
      this.handleDateChange()
      nextProps.onRefetchSuccess()
    }
  }

  handlePageChange = (currentPage, sizePerPage) => {
    this.setState(
      { ...this.state, currentPage, sizePerPage },
      this.handleDateChange,
    )
  }

  handleSortChange = (sort, order) => {
    this.setState({ ...this.state, sort, order }, this.handleDateChange)
  }

  handleFilterChange = (key, value) => {
    this.setState(
      (state) => ({
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      }),
      this.handleDateChange,
    )
  }

  handleClearFilter = () => {
    this.setState(
      {
        ...this.state,
        filter: {},
      },
      this.handleDateChange,
    )
  }

  handleDateChange = () => {
    const { onDataChange } = this.props
    if (onDataChange) onDataChange(this.state)
  }

  render() {
    const { paginatedComponent: Component, ...props } = this.props

    return (
      <Component
        {...props}
        totalCount={this.props.totalCount}
        currentPage={this.state.currentPage}
        sizePerPage={this.state.sizePerPage}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSortChange}
        filter={this.state.filter}
        onClearFilter={this.handleClearFilter}
        onFilterChange={this.handleFilterChange}
      />
    )
  }
}

TableConfig = withDataManager(TableConfig)
