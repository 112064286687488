import React from 'react'
import LoginFormContainer from './LoginFormContainer'

const Login = ({ onLogin, from, username, password, onChange, message }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    handleLogin()
  }
  const handleLogin = () => onLogin({ username, password })
  return (
    <div className="login-container">
      <div className="wrapper">
        <form className="form-signin" onSubmit={handleSubmit}>
          <div className="form-signin-heading" style={{ textAlign: 'center' }}>
            <img
              className="img-nav"
              src={require('../../img/corla-logo.png')}
              alt="logo"
            />
          </div>
          <input
            type="text"
            className="form-control"
            name="username"
            value={username}
            onChange={(e) => onChange('username', e.currentTarget.value)}
            placeholder="Username"
          />
          <input
            type="password"
            className="form-control"
            name="password"
            value={password}
            onChange={(e) => onChange('password', e.currentTarget.value)}
            placeholder="Password"
          />
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

const LoginWithForm = (props) => (
  <LoginFormContainer>
    <Login {...props} />
  </LoginFormContainer>
)

export default LoginWithForm
