import qs from 'qs'
import config from '../modules/config'

let fetchTransformMiddleware = (store) => (next) => (action) => {
  if (action.$payload && action.$payload.qs) {
    if (config.runDev) {
      qsForFakeRest(action.$payload.qs)
    }
    if (action.$payload.qs.filter) {
      action.$payload.qs.filter = JSON.stringify(action.$payload.qs.filter)
    }
    action.$payload.url =
      action.$payload.url + '?' + qs.stringify(action.$payload.qs)
  }

  return next(action)
}

function qsForFakeRest(qs) {
  let range_start = 0
  let range_end = 99999

  if (qs.offset !== undefined) {
    range_start = qs.offset
    delete qs.offset
  }
  if (qs.limit !== undefined) {
    range_end = range_start + qs.limit
    delete qs.limit
  }
  qs.range = `[${range_start},${range_end}]`
}

export default fetchTransformMiddleware
