import React from 'react'
import { Translate } from 'react-redux-i18n'
import {
  Panel,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { I18n } from 'react-redux-i18n'

const ReservationInfo = ({ reservation }) => {
  const dateTimeFormat = I18n.t('date.shortDateTimeFormat')

  if (!reservation.confirmationNumber) return null
  return (
    <div className="reservation-info">
      <ServiceTitle title="Reservation Info" />
      {/* <HrLowMargin /> */}
      <ReservationInfoPanel>
        <Col xs={12}>
          <form className="row">
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.confirmationNumber" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.confirmationNumber}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.mainPaxName" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.mainPax.fullName}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.flightNumber" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.flightNumber}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.luggageAmount" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.luggageAmount
                        ? reservation.luggageAmount
                        : '0'}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.serviceDateTime" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.serviceDateTime &&
                        reservation.serviceDateTime.format(dateTimeFormat)}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.boardingDateTime" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.boardingDateTime &&
                        reservation.boardingDateTime.format(dateTimeFormat)}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <ControlLabel>
                      <Translate value="checkInScene.reservation.targetStationsByService" />
                    </ControlLabel>
                    <FormControl.Static>
                      {reservation.targetStationsByService &&
                        makeStationByServiceTitle(
                          reservation.targetStationsByService,
                        )}
                    </FormControl.Static>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              <Col xs={4}>
                <FormGroup>
                  <ControlLabel>
                    <Translate value="checkInScene.reservation.guestPaxAdult" />
                  </ControlLabel>
                  <FormControl.Static>
                    {reservation && reservation.guestPaxAdult}
                  </FormControl.Static>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <ControlLabel>
                    <Translate value="checkInScene.reservation.guestPaxChild" />
                  </ControlLabel>
                  <FormControl.Static>
                    {reservation && reservation.guestPaxChild}
                  </FormControl.Static>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <ControlLabel>
                    <Translate value="checkInScene.reservation.guestPaxInfant" />
                  </ControlLabel>
                  <FormControl.Static>
                    {reservation && reservation.guestPaxInfant}
                  </FormControl.Static>
                </FormGroup>
              </Col>
            </Col>

            <Col xs={12}>
              <FormGroup>
                <ControlLabel>
                  <Translate value="checkInScene.reservation.importantNote" />
                </ControlLabel>
                <FormControl.Static>
                  {reservation.importantNote ? reservation.importantNote : '-'}
                </FormControl.Static>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup>
                <ControlLabel>
                  <Translate value="checkInScene.reservation.privateNote" />
                </ControlLabel>
                <FormControl.Static>
                  {reservation.privateNote ? reservation.privateNote : '-'}
                </FormControl.Static>
              </FormGroup>
            </Col>

            <Col xs={12}>
              <FormGroup>
                <ControlLabel>
                  <Translate value="checkInScene.reservation.publicNote" />
                </ControlLabel>
                <FormControl.Static>
                  {reservation.publicNote ? reservation.publicNote : '-'}
                </FormControl.Static>
              </FormGroup>
            </Col>
          </form>
        </Col>
      </ReservationInfoPanel>
    </div>
  )
}

const ServiceTitle = ({ title, status }) => (
  <div>
    <h4>
      <b>{title}</b>
    </h4>
  </div>
)

export const makeStationByServiceTitle = (stationByService) =>
  stationByService
    .filter((target) => target.station)
    .map((target) => target.station.name)
    .join(', ')

export default connect(({ checkin }) => ({
  reservation: checkin.serviceTxn.reservation,
}))(ReservationInfo)

const ReservationInfoPanel = styled(Panel).attrs({ className: 'panel-kiosk' })`
  max-height: 88%;
  overflow: auto;
  margin-bottom: 0px;
`
