import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import storage from '../../storage'
import StationMenuItem from './StationMenuItem'
import { fetchServiceStations } from '../../actions/remote_api'
import AppBar from '../../components/AppBar'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

class StationScene extends React.Component {
  componentDidMount() {
    const { serviceStations, fetchServiceStations } = this.props
    if (!serviceStations) fetchServiceStations()
  }

  handleStationClick = (station) => {
    const info = storage.info()
    storage.saveInfo({
      ...info,
      serviceId: station.serviceId,
      serviceClass: station.serviceClass,
      serviceDisplayName: station.serviceDisplayName,
      stationId: station.id,
      stationName: station.name,
    })
    this.props.history.push('/checkin')
  }

  render() {
    const { serviceStations } = this.props
    const info = storage.info()
    const serviceByStation = (serviceStations || []).find(
      (s) => s.name === info.serviceName,
    )
    const stations = serviceByStation ? serviceByStation.stations : []
    let settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    }

    if (serviceStations && stations.length <= 3) {
      settings = {
        dots: false,
        infinite: false,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: undefined,
        draggable: false,
        swipe: false,
      }
    }

    if (stations.length < 1) {
      return (
        <div className="station">
          <AppBar header={info.serviceName} subHeader="Stations" />
          <div className="kiosk-container">
            <div className="slider-container">
              <div className="well text-center">
                <h1>
                  <span className="glyphicon glyphicon-remove" /> <br /> Can't
                  find station.
                </h1>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="station">
        <AppBar header={info.serviceName} subHeader="Stations" />
        <div className="kiosk-container">
          <div className="slider-container">
            {serviceStations && (
              <Slider {...settings}>
                {stations.map((station) => (
                  <div key={station.id}>
                    <StationMenuItem
                      station={station}
                      onStationClick={this.handleStationClick}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    ({ fetch }) => ({
      serviceStations: fetch.serviceStation
        ? fetch.serviceStation.list
        : undefined,
    }),
    { fetchServiceStations },
  )(StationScene),
)
