import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import Select from 'react-select'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  return (
    <Panel collapsible expanded={isShow} className="no-border">
      <Form horizontal>
        <Row>
          <Col md={3}>
            <Select
              value={filter.checkInType}
              onChange={(value) => {
                if (value !== 'walkin')
                  onFilterChange('walkinCheckInType', null)

                onFilterChange('checkInType', value)
              }}
              placeholder="Check-in Type"
              simpleValue
              options={checkInTypeOptions}
            />
          </Col>
          <Col md={4}>
            <FormControl
              type="text"
              value={filter.reservationMainPaxName || ''}
              onChange={(e) =>
                onFilterChange('reservationMainPaxName', e.currentTarget.value)
              }
              placeholder="Passenger Name"
            />
          </Col>
          <Col md={3}>
            <FormControl
              type="text"
              value={filter.flightNumber || ''}
              onChange={(e) =>
                onFilterChange('flightNumber', e.currentTarget.value)
              }
              placeholder="Fligth Number"
            />
          </Col>
          {/* <Col md={4}>
          <FormControl
            type="text"
            value={filter.airlineName || ''}
            onChange={e => onFilterChange('airlineName', e.currentTarget.value)}
            placeholder="Airline" />
        </Col>
        <Col md={2}>
          <FormControl
            type="text"
            value={filter.agentName || ''}
            onChange={e => onFilterChange('agentName', e.currentTarget.value)}
            placeholder="Agent" />
        </Col>
        <Col md={2}>
          <FormControl
            type="text"
            value={filter.cardName || ''}
            onChange={e => onFilterChange('cardName', e.currentTarget.value)}
            placeholder="Card" />
        </Col>
        <Col md={2}>
          <Checkbox
            checked={filter.isCash || false}
            onChange={e => onFilterChange('isCash', e.currentTarget.checked)}>
            Cash
          </Checkbox>
        </Col> */}
          <Col md={2}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash" /> Clear Filter
            </Button>
          </Col>
        </Row>
        <Row>
          {filter.checkInType === 'walkin' && (
            <Col md={3}>
              <Select
                value={filter.walkinCheckInType}
                onChange={(value) => onFilterChange('walkinCheckInType', value)}
                placeholder="Walk-in Type"
                simpleValue
                options={walkinCheckInTypeOptions}
              />
            </Col>
          )}
        </Row>
      </Form>
    </Panel>
  )
}

const checkInTypeOptions = [
  { label: 'Airline', value: 'airline' },
  { label: 'Agent', value: 'agent' },
  { label: 'Walkin', value: 'walkin' },
]

const walkinCheckInTypeOptions = [
  { label: 'Cash', value: 'cash' },
  { label: 'Credit Card', value: 'creditCash' },
  { label: 'Card', value: 'card' },
  { label: 'Others', value: 'others' },
]
