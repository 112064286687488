import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { refresh } from 'react-icons-kit/ionicons'

export default ({ onClick }) => (
  <Button bsStyle="info" bsSize="lg" onClick={onClick}>
    <Icon icon={refresh} /> Refresh
  </Button>
)
