import React from 'react'
import ScanCodeModal from './ScanCodeModal'

export const withScanCodeModal = (Component, Modal = ScanCodeModal) => {
  return class ScanCodeModalWrapper extends React.Component {
    state = {
      isShow: false,
    }

    handleShowModal = () => {
      this.setState((state) => ({
        isShow: true,
      }))
    }

    handleHide = () => {
      this.setState((state) => ({
        isShow: false,
      }))
    }

    render() {
      return (
        <div>
          <Component
            {...this.props}
            isScanCodeModalShow={this.state.isShow}
            showScanCodeModal={this.handleShowModal}
            hideScanCodeModal={this.handleHide}
          />

          <Modal isShow={this.state.isShow} onHide={this.handleHide} />
        </div>
      )
    }
  }
}
