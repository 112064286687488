import React from 'react'
import { withTableConfigs } from '../../components/table'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { ButtonGroup, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import CheckInSceneLinkButton from './CheckInSceneLinkButton'
import ServiceTxnTableFilterPanel from './ServiceTxnTableFilterPanel'
import { ServiceTxnCancelConfirmModal } from '../../components/ServiceTxnCancelConfirmModal'
import CheckInInfo from '../../components/serviceTxn/CheckInInfo'

const ServiceTxnTable = ({
  defaultOptions,
  defaultSettings,
  settings,
  onCheckoutClick,
  onFeedbackClick,
  onExtendDurationClick,
  renderFilterPanel,
  isCancelable,
  serviceTxnDidConfirm,
  renderToolbar,
  actionable = true,
  ...props
}) => {
  return (
    <div>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable
        {...defaultSettings}
        {...settings}
        bodyStyle={{ height: '44vh' }}
        trClassName={rowClassNameFormat}
        options={defaultOptions}
      >
        <TableHeaderColumn
          dataField="customerName"
          dataFormat={renderCustomerFullName}
        >
          Passenger Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="checkInType"
          dataFormat={renderCheckInInfo}
        >
          Check-In Info
        </TableHeaderColumn>
        <TableHeaderColumn dataField="seatNumber" width="11%">
          Seat No.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="serviceInfo"
          width="11%"
          dataFormat={({ inTime }) => renderTime(inTime)}
        >
          Start Time
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="serviceInfo"
          width="10%"
          dataFormat={({ issuedOutTime }) => renderTime(issuedOutTime)}
        >
          End Time
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="serviceInfo"
          dataFormat={({ outTime }) => renderTime(outTime)}
        >
          Out Time
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="serviceInfo"
          width="10%"
          dataFormat={({ overTime }) => (overTime ? overTime + ' hr.' : '-')}
        >
          Over Time
        </TableHeaderColumn>
        {actionable && (
          <TableHeaderColumn
            dataAlign="center"
            dataFormat={createRenderTableActions({
              onCheckoutClick,
              onFeedbackClick,
              onExtendDurationClick,
              isCancelable,
              serviceTxnDidConfirm,
            })}
          >
            Actions
          </TableHeaderColumn>
        )}
      </BootstrapTable>
    </div>
  )
}

const renderCustomerFullName = (customerName, serviceTxn) => (
  <CheckInSceneLinkButton serviceTxn={serviceTxn}>
    {customerName || '-'}
  </CheckInSceneLinkButton>
)

const renderCheckInInfo = (checkInType, serviceTxn) => {
  return <CheckInInfo serviceTxn={serviceTxn} />
}

const renderTime = (dateTime) => {
  const timeFormat = I18n.t('date.timeFormat')
  return dateTime ? dateTime.format(timeFormat) : '-'
}

const rowClassNameFormat = (serviceTxn, rowIdx) => {
  if (serviceTxn.stateCode === 2) return 'warning'
  else if (serviceTxn.stateCode === 1) return 'danger'
  else return 'default'
}

const createRenderTableActions =
  ({
    onCheckoutClick,
    onFeedbackClick,
    onExtendDurationClick,
    isCancelable,
    serviceTxnDidConfirm,
  }) =>
  (_, serviceTxn) => (
    <ServiceTxnCancelConfirmModal
      serviceTxn={serviceTxn}
      serviceTxnDidConfirm={serviceTxnDidConfirm}
    >
      {(showModal) => (
        <ButtonGroup>
          <Button
            onClick={() => onCheckoutClick(serviceTxn)}
            bsStyle="primary"
            bsSize="lg"
          >
            <span className="glyphicon glyphicon-ok" />
          </Button>
          {isCancelable(serviceTxn) && (
            <Button onClick={showModal} bsStyle="danger" bsSize="lg">
              <span className="glyphicon glyphicon-remove" />
            </Button>
          )}
          <Button
            onClick={() => onFeedbackClick(serviceTxn)}
            bsSize="lg"
            bsStyle="default"
          >
            <span className="glyphicon glyphicon-list-alt" />
          </Button>
          {serviceTxn.serviceInfo.overTime > 0 && (
            <Button
              bsSize="lg"
              onClick={() => onExtendDurationClick(serviceTxn)}
              bsStyle="warning"
            >
              <span className="glyphicon glyphicon-hourglass" />
            </Button>
          )}
        </ButtonGroup>
      )}
    </ServiceTxnCancelConfirmModal>
  )

export default withTableConfigs(ServiceTxnTable, ServiceTxnTableFilterPanel)
