import React from 'react'
import StationReportTabContainer from './StationReportTabContainer'
import AppBar from '../../components/AppBar'
import storage from '../../storage'

class StationReportScene extends React.Component {
  render() {
    const { stationName, serviceDisplayName } = storage.info()

    return (
      <div className="service">
        <AppBar
          header="Station Report"
          subHeader={`Station: (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup
        />
        <div className="kiosk-container">
          <div className="kiosk-panel station-report">
            <div className="col-md-12 col-sm-12 col-xs-12 kiosk-fullheight ">
              <StationReportTabContainer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StationReportScene
