import React from 'react'
import WorkSummaryDetailViewJobAssignmentTable from './WorkSummaryDetailViewJobAssignmentTable'
import styled from 'styled-components'

const WorkSummaryDetailView = ({
  workSummary,
  jobAssignmentsTableTitle,
  getJobAssignments,
}) => {
  if (!workSummary) return <NoData>No work summary selected</NoData>

  return (
    <div className="full-height">
      <div className="title">
        {workSummary.staff.firstName} {workSummary.staff.lastName} [{' '}
        {jobAssignmentsTableTitle} ]
      </div>

      <hr />
      {renderTable(getJobAssignments(workSummary))}
    </div>
  )
}

const renderTable = (jobAssignments) => {
  if (jobAssignments.length <= 0) return <NoData>No Completed Job</NoData>
  return (
    <WorkSummaryDetailViewJobAssignmentTable jobAssignments={jobAssignments} />
  )
}

export default WorkSummaryDetailView

export const withWorkSummaryDetailView =
  (jobAssignmentsTableTitle, getJobAssignments) => (props) => (
    <WorkSummaryDetailView
      jobAssignmentsTableTitle={jobAssignmentsTableTitle}
      getJobAssignments={getJobAssignments}
      {...props}
    />
  )

const NoData = styled.div.attrs({ className: 'row-alirline' })`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 30%;
`
