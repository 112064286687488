import React from 'react'
import { FormGroup, InputGroup, FormControl, Glyphicon } from 'react-bootstrap'
import styled from 'styled-components'
import ReservationCheckInTypeItem from './ReservationCheckInTypeItem'

export default ({
  reservations = [],
  onReservationSelect,
  filterText,
  onFilterTextChange,
}) => (
  <Container>
    <div className="col-md-12 tab-search-field">
      <FormGroup>
        <InputGroup>
          <FormControl
            type="text"
            value={filterText}
            onChange={(e) => onFilterTextChange(e.currentTarget.value)}
          />
          <InputGroup.Addon>
            <Glyphicon glyph="search" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    </div>
    <div className="scroll-container">
      {renderReservationItems(reservations, onReservationSelect, filterText)}
    </div>
  </Container>
)

const renderReservationItems = (
  reservations,
  onReservationSelect,
  filterText,
) => {
  if (filterText && reservations.length <= 0) return <NoData>No data.</NoData>

  if (reservations.length <= 0)
    return (
      <NoData>Fill some reservation's information to start searching.</NoData>
    )

  return reservations.map((reservation) => (
    <ReservationCheckInTypeItem
      key={reservation.id}
      reservation={reservation}
      onClick={onReservationSelect}
    />
  ))
}

const Container = styled.div`
  height: 100%;
`

const NoData = styled.div.attrs({ className: 'col-xs-12 row-alirline' })`
  text-align: center;
  margin-left: 0px;
`
