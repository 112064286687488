import React from 'react'
import ServiceTxnTableContainer from './ServiceTxnTableContainer'
import { connect } from 'react-redux'
import { transformServiceTxns, isCancelable } from '../../utils/serviceTxn'
import { I18n } from 'react-redux-i18n'
import {
  fetchServiceTxns,
  updateServiceTxn,
  fetchPaxCountByStation,
} from '../../actions/remote_api'
import storage, { info } from '../../storage'
import CheckoutModal from '../../components/CheckoutModal'
import FeedbackModal from '../../components/FeedbackModal'
import ExtendDurationModalContainer from './ExtendDurationModalContainer'
import MultiCheckoutModal from './MultiCheckoutModal'
import { toastr } from 'react-redux-toastr'
import { ButtonGroup } from 'react-bootstrap'
import CheckoutButton from './CheckoutButton'
import RefreshButton from './RefreshButton'
import { keyBy, keys, isEmpty } from 'lodash'

const types = {
  OPEN_CHECKOUT_MODAL: 'OPEN_CHECKOUT_MODAL',
  CLOSE_CHECKOUT_MODAL: 'CLOSE_CHECKOUT_MODAL',
  OPEN_FEEDBACK_MODAL: 'OPEN_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL: 'CLOSE_FEEDBACK_MODAL',
  OPEN_MULTICHECKOUT_MODAL: 'OPEN_MULTICHECKOUT_MODAL',
  CLOSE_MULTICHECKOUT_MODAL: 'CLOSE_MULTICHECKOUT_MODAL',
  OPEN_EXTEND_DURATION_MODAL: 'OPEN_EXTEND_DURATION_MODAL',
  CLOSE_EXTEND_DURATION_MODAL: 'CLOSE_EXTEND_DURATION_MODAL',
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.OPEN_CHECKOUT_MODAL:
      return {
        ...state,
        isCheckoutModalOpen: true,
        serviceTxnToCheckout: action.serviceTxn,
      }
    case types.CLOSE_CHECKOUT_MODAL:
      return {
        ...state,
        isCheckoutModalOpen: false,
        serviceTxnToCheckout: undefined,
      }
    case types.OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        isFeedbackModalOpen: true,
        serviceTxnToFeedback: action.serviceTxn,
      }
    case types.CLOSE_FEEDBACK_MODAL:
      return {
        ...state,
        isFeedbackModalOpen: false,
        serviceTxnToFeedback: undefined,
      }
    case types.OPEN_MULTICHECKOUT_MODAL:
      return {
        ...state,
        isMultiCheckoutModalOpen: true,
        serviceTxnsToCheckout: action.selectedServiceTxns,
      }
    case types.CLOSE_MULTICHECKOUT_MODAL:
      return {
        ...state,
        isMultiCheckoutModalOpen: false,
        serviceTxnsToCheckout: undefined,
      }
    default:
      return {
        ...state,
        extendDuration: extendDuration(state.extendDuration, action),
      }
  }
}

const extendDuration = (state, action) => {
  switch (action.type) {
    case types.OPEN_EXTEND_DURATION_MODAL:
      return {
        ...state,
        isModalOpen: true,
        serviceTxn: action.serviceTxn,
      }
    case types.CLOSE_EXTEND_DURATION_MODAL:
      return {
        ...state,
        isModalOpen: false,
      }
    default:
      return state
  }
}

class ActiveServiceTxnTableContainer extends React.Component {
  state = {
    isCheckoutModalOpen: false,
    serviceTxnToCheckout: undefined,
    isFeedbackModalOpen: false,
    serviceTxnToFeedback: undefined,
    isMultiCheckoutModalOpen: false,
    serviceTxnsToCheckout: undefined,
    extendDuration: {
      isModalOpen: false,
      serviceTxn: undefined,
    },
  }

  selectedServiceTxns = {}
  lastPagination = undefined

  openCheckoutModal = (serviceTxn) => {
    this.setState((state) =>
      reducer(state, {
        type: types.OPEN_CHECKOUT_MODAL,
        serviceTxn,
      }),
    )
  }

  closeCheckoutModal = () => {
    this.setState((state) =>
      reducer(state, {
        type: types.CLOSE_CHECKOUT_MODAL,
      }),
    )
  }

  handleCheckOut = (serviceTxn, outTime) => {
    const { updateServiceTxn } = this.props
    updateServiceTxn(
      {
        ...serviceTxn,
        serviceInfo: {
          ...serviceTxn.serviceInfo,
          outTime,
        },
      },
      (response) => {
        if (response.ok) {
          toastr.success(I18n.t('toastr.saveSuccess'))
          this.setState((state) =>
            reducer(state, {
              type: types.CLOSE_CHECKOUT_MODAL,
            }),
          )
          this.fetchServiceTxns()
        } else {
          toastr.error('Error', 'something went wrong')
        }
      },
    )
  }

  openFeedbackModal = (serviceTxn) => {
    this.setState((state) =>
      reducer(state, {
        type: types.OPEN_FEEDBACK_MODAL,
        serviceTxn,
      }),
    )
  }

  closeFeedbackModal = () => {
    this.setState((state) =>
      reducer(state, {
        type: types.CLOSE_FEEDBACK_MODAL,
      }),
    )
  }

  handleFeedback = (serviceTxn, feedback) => {
    const { updateServiceTxn } = this.props
    updateServiceTxn(
      {
        ...serviceTxn,
        serviceInfo: {
          ...serviceTxn.serviceInfo,
          ...feedback,
        },
      },
      (response) => {
        if (response.ok) {
          toastr.success(I18n.t('toastr.saveSuccess'))
          this.setState((state) =>
            reducer(state, {
              type: types.CLOSE_FEEDBACK_MODAL,
            }),
          )
        } else {
          toastr.error('Error', 'something went wrong')
        }
      },
    )
  }

  fetchServiceTxns = (pagination) => {
    if (pagination) this.lastPagination = pagination

    toastr.info('Loading')
    this.props.fetchServiceTxns({
      ...this.lastPagination,
      checkedout: false,
      operationDaily: true,
      stationId: storage.info().stationId,
    })
    this.props.fetchPaxCountByStation(info().stationId)
  }

  renderButtonGroup = () => {
    return (
      <ButtonGroup>
        <CheckoutButton onClick={this.openMultiCheckout} />
        <RefreshButton onClick={this.handleRefresh} />
      </ButtonGroup>
    )
  }

  handleRefresh = () => {
    toastr.info('Refreshed')
    this.fetchServiceTxns()
  }

  openMultiCheckout = () => {
    const selectedServiceTxns = this.getSelectedServiceTxns()
    if (selectedServiceTxns.length <= 0) return

    this.setState((state) =>
      reducer(state, {
        type: types.OPEN_MULTICHECKOUT_MODAL,
        selectedServiceTxns,
      }),
    )
  }

  handleMultiCheckOut = (serviceTxnsToCheckout, outTime) => {
    const { updateServiceTxn } = this.props
    serviceTxnsToCheckout.forEach((serviceTxn) => {
      updateServiceTxn({
        ...serviceTxn,
        serviceInfo: {
          ...serviceTxn.serviceInfo,
          outTime,
        },
      })
    })
    setTimeout(() => {
      this.props.fetchPaxCountByStation(info().stationId)
    }, 1000)
    toastr.success(I18n.t('toastr.saveSuccess'))
    this.closeMultiCheckoutModal()
  }

  closeMultiCheckoutModal = () => {
    this.setState((state) =>
      reducer(state, {
        type: types.CLOSE_MULTICHECKOUT_MODAL,
      }),
    )
  }

  onServiceTxnSelect = (serviceTxn, value) => {
    if (value) this.selectedServiceTxns[serviceTxn.id] = serviceTxn
    else delete this.selectedServiceTxns[serviceTxn.id]
  }

  onServiceTxnSelectAll = (value) => {
    this.selectedServiceTxns = value ? keyBy(this.props.serviceTxns, 'id') : {}
  }

  getSelectedServiceTxns() {
    return this.props.serviceTxns.filter((serviceTxn) =>
      keys(this.selectedServiceTxns).some((id) => id === serviceTxn.id),
    )
  }

  settings = {
    selectRow: {
      mode: 'checkbox',
      onSelect: this.onServiceTxnSelect,
      onSelectAll: this.onServiceTxnSelectAll,
    },
  }

  openExtendDurationModal = (serviceTxn) => {
    this.setState((state) =>
      reducer(state, {
        type: 'OPEN_EXTEND_DURATION_MODAL',
        serviceTxn,
      }),
    )
  }

  closeExtendDurationModal = () => {
    this.setState((state) =>
      reducer(state, {
        type: 'CLOSE_EXTEND_DURATION_MODAL',
      }),
    )
  }

  serviceTxnDidConfirm = () => {
    this.fetchServiceTxns()
    toastr.success(I18n.t('cancelServiceTxnModal.toast'))
  }

  render() {
    return (
      <div>
        <ServiceTxnTableContainer
          {...this.props}
          instances={this.props.serviceTxns}
          fetchInstances={this.fetchServiceTxns}
          onCheckoutClick={this.openCheckoutModal}
          onFeedbackClick={this.openFeedbackModal}
          onExtendDurationClick={this.openExtendDurationModal}
          totalCount={this.props.totalCount}
          btnGroup={this.renderButtonGroup}
          settings={this.settings}
          isCancelable={isCancelable}
          serviceTxnDidConfirm={this.serviceTxnDidConfirm}
        />

        <CheckoutModal
          isShow={this.state.isCheckoutModalOpen}
          onHide={this.closeCheckoutModal}
          serviceTxn={this.state.serviceTxnToCheckout}
          onCheckOut={this.handleCheckOut}
        />

        <FeedbackModal
          isShow={this.state.isFeedbackModalOpen}
          onHide={this.closeFeedbackModal}
          serviceTxn={this.state.serviceTxnToFeedback}
          onFeedback={this.handleFeedback}
        />

        <MultiCheckoutModal
          isShow={this.state.isMultiCheckoutModalOpen}
          onHide={this.closeMultiCheckoutModal}
          serviceTxnsToCheckout={this.state.serviceTxnsToCheckout}
          onCheckOut={this.handleMultiCheckOut}
        />

        <ExtendDurationModalContainer
          isShow={this.state.extendDuration.isModalOpen}
          onHide={this.closeExtendDurationModal}
          serviceTxn={this.state.extendDuration.serviceTxn}
        />
      </div>
    )
  }
}

export default connect(
  ({ fetch }) => ({
    serviceTxns: fetch.serviceTxn
      ? activeServiceTxns(transformServiceTxns(fetch.serviceTxn.list))
      : undefined,
    totalCount: fetch.serviceTxn ? fetch.serviceTxn.totalCount : undefined,
  }),
  {
    fetchServiceTxns,
    updateServiceTxn,
    fetchPaxCountByStation,
  },
)(ActiveServiceTxnTableContainer)

const activeServiceTxns = (serviceTxns) =>
  serviceTxns.filter((serviceTxn) => isEmpty(serviceTxn.serviceInfo.outTime))
