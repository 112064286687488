import React from 'react'
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Glyphicon,
  Label,
} from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import Autosuggest from 'react-autosuggest'
import InputMoment from 'input-moment'
import Select from 'react-select'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactModel from 'react-modal'

import { touchCheckInServiceTxnField } from '../../actions/checkin'
import ProductSearchSelect from '../../modules/product/ProductSearchSelect'
import { debounce } from 'lodash'

// HOC

const withTouch = (Component) =>
  connect(null, { touchCheckInServiceTxnField })(withTouchComponent(Component))

const withTouchComponent =
  (Component) =>
  ({ touchCheckInServiceTxnField, onBlur, ...props }) => {
    const handleBlur = (e) => {
      touchCheckInServiceTxnField(props.name)
      if (onBlur) onBlur(e)
    }
    return <Component {...props} onBlur={handleBlur} />
  }

const createFieldGroup = (Component) => (props) => {
  const handleChange = (value) => {
    withField(props.name, props.onChange)(value)
  }

  return <FieldGroup {...props} component={Component} onChange={handleChange} />
}

const withField = (name, onChange) => (value) => onChange(name, value)

const FieldGroup = ({
  name,
  value,
  onChange,
  onBlur,
  i18n,
  error,
  touched = false,
  pristine = true,
  component: Component,
  ...rest
}) => {
  return (
    <FormGroup bsSize="lg" controlId={name}>
      <Col sm={12}>
        <Col componentClass={ControlLabel}>
          <Translate value={i18n} />{' '}
          {(touched || !pristine) && error && (
            <Glyphicon style={{ top: 3, marginRight: 2 }} glyph="info-sign" />
          )}
        </Col>
        <FieldBlock onBlur={onBlur}>
          <Component name={name} value={value} onChange={onChange} {...rest} />
        </FieldBlock>
      </Col>
      <ReactTooltip effect="solid" />
    </FormGroup>
  )
}

const FieldBlock = styled.div`
  min-height: 68px;
  display: flex;
  flex-direction: column;
`

// MARK: - Fields

// TextField

const TextField = ({ value, onChange, type = 'text' }) => (
  <FormControl
    className="kiosk-value"
    type={type}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    inputRef={(ref) => {
      if (ref) ref.spellcheck = false
    }}
  />
)
export const TextFieldGroup = withTouch(createFieldGroup(TextField))

const TextAreaField = ({ value, onChange }) => (
  <FormControl
    className="kiosk-value"
    componentClass="textarea"
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
)

export const TextAreaFieldGroup = withTouch(createFieldGroup(TextAreaField))

// AutosuggestTextField

class AutosugguestTextField extends React.Component {
  state = { suggestions: [] }

  constructor(props) {
    super(props)

    this.onSuggestionsFetchRequested = debounce(
      this.onSuggestionsFetchRequested,
      300,
    )
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    const { loadSuggestions, getSuggestions } = this.props
    if (loadSuggestions) {
      this.setState({
        suggestions: await loadSuggestions(value),
      })
    } else {
      this.setState({
        suggestions: getSuggestions(value),
      })
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    const {
      value,
      onChange,
      getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected,
    } = this.props
    const inputProps = {
      value,
      onChange: (event, { newValue }) => onChange(newValue),
      spellCheck: false,
    }
    const handleSuggestionSelected = (event, { suggestion }) => {
      if (onSuggestionSelected) onSuggestionSelected(suggestion)
    }
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={handleSuggestionSelected}
      />
    )
  }
}

export const AutosugguestTextFieldGroup = withTouch(
  createFieldGroup(AutosugguestTextField),
)

const toggleCalendar = (isOpen) => (state) => ({
  ...state,
  isCalendarOpen: isOpen,
})

const openCalendar = toggleCalendar(true)
const closeCalendar = toggleCalendar(false)

class DateTimeField extends React.Component {
  state = {
    isCalendarOpen: false,
  }

  renderDateTime() {
    const { dateFormat } = this.props
    const value = this.getValue()
    if (value) return value.format(dateFormat)
    return null
  }

  openCalendar = () => this.setState(openCalendar)
  closeCalender = () => this.setState(closeCalendar)

  handleValueChange = (prevMoment) => {
    this.props.onChange(new moment(prevMoment))
  }

  calendarParentSelector = () => document.getElementById('root')

  getValue = () => this.props.value || moment()

  render() {
    return (
      <DateTimeFieldLabelLayout>
        <DateTimeFieldLabel onClick={this.openCalendar}>
          {this.renderDateTime()}
        </DateTimeFieldLabel>
        <ReactModel
          isOpen={this.state.isCalendarOpen}
          onRequestClose={this.closeCalender}
          style={dateTimeFieldCalendarModalStyle}
          parentSelector={this.calendarParentSelector}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          <CalendarModalBodyLayout>
            <InputMoment
              moment={this.getValue()}
              onChange={this.handleValueChange}
            />
          </CalendarModalBodyLayout>
        </ReactModel>
      </DateTimeFieldLabelLayout>
    )
  }
}

ReactModel.setAppElement(document.getElementById('root'))

const dateTimeFieldCalendarModalStyle = {
  overlay: {
    backgroundColor: '#757575ba', // black overlay partial see through
    zIndex: '2000',
  },
  content: {
    pointerEvents: 'none',
    backgroundColor: undefined,
    display: 'flex',
    border: '0',
  },
}

const CalendarModalBodyLayout = styled.div`
  pointer-events: auto;
  margin: auto;
  background-color: #eaebe9;
  border-radius: 3px;
`

const DateTimeFieldLabelLayout = styled.div`
  display: flex;
  flex: 1;
`

const DateTimeFieldLabel = styled(Label)`
  font-size: 24px;
  margin: auto 0;
  cursor: pointer;
`

export const DateTimeFieldGroup = withTouch(createFieldGroup(DateTimeField))

// SelectField

const SelectField = ({
  value,
  onChange,
  options,
  simpleValue = true,
  ...props
}) => (
  <Select
    value={value}
    options={options}
    simpleValue={simpleValue}
    onChange={(optionOrValue) =>
      onChange(selectValue(optionOrValue, simpleValue))
    }
    openOnFocus
    {...props}
  />
)

export const SelectFieldGroup = withTouch(createFieldGroup(SelectField))

const SelectAsyncField = ({
  value,
  onChange,
  loadOptions,
  simpleValue = true,
  ...props
}) => (
  <Select.Async
    value={value}
    loadOptions={loadOptions}
    simpleValue={simpleValue}
    onChange={(optionOrValue) =>
      onChange(selectValue(optionOrValue, simpleValue))
    }
    openOnFocus
    {...props}
  />
)

export const SelectAsyncFieldGroup = withTouch(
  createFieldGroup(SelectAsyncField),
)

const selectValue = (optionOrValue, isSimpleValue) => {
  if (isSimpleValue) return optionOrValue // it's is value already
  return optionOrValue ? optionOrValue.value : null
}

export const ProductSearchSelectFieldGroup = withTouch(
  createFieldGroup(ProductSearchSelect),
)
