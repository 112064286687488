import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap'

export default ({ isShow, onHide, type, recentMarkFinalServiceTxns }) => (
  <Modal show={isShow} onHide={onHide} backdrop="static">
    <ModalHeader closeButton>
      <ModalTitle>
        Scan Code: {type ? type.toUpperCase() : undefined}
      </ModalTitle>
    </ModalHeader>
    <ModalBody>
      <h2 className="text-center">
        <strong>Waiting for code scaning</strong>
      </h2>
      {renderMarkFinalServiceTxns(recentMarkFinalServiceTxns)}
    </ModalBody>
  </Modal>
)

const renderMarkFinalServiceTxns = (recentMarkFinalServiceTxns) => {
  if (!recentMarkFinalServiceTxns) return null

  return (
    <ListGroup>
      {recentMarkFinalServiceTxns.map((serviceTxn) => (
        <ListGroupItem
          key={serviceTxn._id}
          header={`${serviceTxn.service.displayName}`}
        >
          CN: {serviceTxn.reservation.confirmationNumber} - SEQ:{' '}
          {serviceTxn.seq} - {serviceTxn.customerName}
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}
