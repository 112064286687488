import React from 'react'
import { FormGroup, InputGroup, FormControl, Glyphicon } from 'react-bootstrap'
import styled from 'styled-components'
import CashCheckInTypeItem from './CashCheckInTypeItem'
import CreditCardCheckInTypeItem from './CreditCardCheckInTypeItem'
import CardCheckInTypeItem from './CardCheckInTypeItem'
import OthersCheckInTypeItem from './OthersCheckInTypeItem'

export default ({
  onCashSelect,
  onCreditCardSelect,
  onOthersSelect,
  cards = [],
  onCardSelect,
  filterText,
  onFilterTextChange,
}) => (
  <Container>
    <div className="col-md-12 tab-search-field">
      <FormGroup>
        <InputGroup>
          <FormControl
            type="text"
            value={filterText}
            onChange={(e) => onFilterTextChange(e.currentTarget.value)}
          />
          <InputGroup.Addon>
            <Glyphicon glyph="search" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    </div>
    <div className="scroll-container">
      <div className="row row-card">
        <CashCheckInTypeItem onClick={onCashSelect} />
        <CreditCardCheckInTypeItem onClick={onCreditCardSelect} />
        <OthersCheckInTypeItem onClick={onOthersSelect} />
        <Hr />
        {renderCardItems(cards, onCardSelect)}
      </div>
    </div>
  </Container>
)

const renderCardItems = (cards, onCardSelect) => {
  if (cards.length <= 0) return null
  return cards.map((card) => (
    <CardCheckInTypeItem key={card.id} card={card} onClick={onCardSelect} />
  ))
}

const Container = styled.div`
  height: 100%;
`
const Hr = styled.hr`
  margin: 0 20px 12px;
`
