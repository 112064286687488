import React from 'react'
import { Link } from 'react-router-dom'
import Background from '../../../src/img/bg.jpg'
import { Badge } from 'react-bootstrap'

export default ({ to, title, notificationCount }) => (
  <Link to={to}>
    <div className="summaryReport">
      <Badge className="circle">{notificationCount}</Badge>
      <div
        className="imageDisplay"
        style={{ backgroundImage: `url(${Background})` }}
      ></div>
      <div className="title">
        <span>{title}</span>
      </div>
    </div>
  </Link>
)
