import { updateServiceTxn } from './remote_api'
import { extendDuration } from '../utils/serviceTxn'

export const extendServiceTxnDuration = (serviceTxn, duration, onResponse) => {
  if (!serviceTxn || !serviceTxn._id)
    throw new Error(
      'Only existing service txn can be extended, new service txn could not be extendable',
    )
  return updateServiceTxn(extendDuration(serviceTxn, duration), onResponse)
}

export const completeServiceTxn = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/serviceTxns/${id}/complete`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    },
    onResponse,
  },
})

export const noShowServiceTxn = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/serviceTxns/${id}/noShow`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    },
    onResponse,
  },
})

export const missedCaseServiceTxn = (id, { fetchId, onResponse }) => ({
  type: `UPDATE_${fetchId.toUpperCase()}`,
  meta: { fetch_id: fetchId, type: 'update' },
  $payload: {
    url: `/api/serviceTxns/${id}/missedCase`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    },
    onResponse,
  },
})
