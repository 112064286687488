import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import TimeKeeper from 'react-timekeeper'

export default class CheckOutModal extends React.Component {
  state = {
    outTime: moment(),
  }

  handleOutTimeChange = (outTime) => {
    this.setState({ outTime })
  }

  handleCheckOut = () => {
    const { serviceTxn, onCheckOut } = this.props
    onCheckOut(serviceTxn, this.state.outTime)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isShow !== this.props.isShow) {
      this.setState({ outTime: moment() })
    }
  }

  render() {
    const { isShow, onHide, serviceTxn } = this.props
    const label = I18n.t('stationReportScene.checkOutModal')
    return (
      <Modal className="top-reduce-modal" show={isShow} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>{label.title.toUpperCase()}</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderModalBody(
            serviceTxn,
            label,
            this.state.outTime,
            this.handleOutTimeChange,
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button bsSize="lg" onClick={onHide}>
            {I18n.t('action.cancel')}
          </Button>
          <Button bsSize="lg" onClick={this.handleCheckOut} bsStyle="primary">
            {I18n.t('action.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const renderModalBody = (serviceTxn, label, outTime, onOutTimeChange) => {
  const timeFormat = I18n.t('date.timeFormat')
  if (serviceTxn) {
    const serviceInfo = serviceTxn.serviceInfo
    return (
      <dl className="dl-horizontal" style={{ fontSize: 18 }}>
        <dt>{label.customer}</dt>
        <dd style={{ color: 'black' }}>
          {serviceTxn.reservation.mainPax.fullName}
        </dd>
        <dt>{label.inTime}</dt>
        <dd style={{ color: 'black' }}>
          {serviceInfo.inTime !== undefined &&
            moment(serviceInfo.inTime).format(timeFormat)}
        </dd>
        <dt>{label.outTime}</dt>
        <dd>
          <MomentTimeKeeper value={outTime} onChange={onOutTimeChange} />
        </dd>
      </dl>
    )
  }
  return null
}

const MomentTimeKeeper = ({ value, onChange, ...props }) => (
  <TimeKeeper
    {...props}
    time={momentToTime(value)}
    onChange={createOnTimeChangeHandler(onChange)}
  />
)

const momentToTime = (momentDate) => {
  return momentDate ? momentDate.format('hh:mm a') : null
}

const createOnTimeChangeHandler = (onChange) => (time) => {
  return onChange(time ? moment(time.formatted, 'hh:mm a') : null)
}
