import React from 'react'
import AssignmentPendingTableContainer from './AssignmentPendingTableContainer'
import AppBar from '../../components/AppBar'
import storage from '../../storage'
import BackButton from '../../components/BackButton'

export default class AssignmentOnQueueScene extends React.Component {
  handleBackScene = () => {
    if (this.props.history) this.props.history.goBack()
  }

  render() {
    const { stationName, serviceDisplayName } = storage.info()
    return (
      <div className="service">
        <AppBar
          header="Assignment"
          subHeader={`Pending (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup
        />
        <div className="kiosk-container">
          <div className="kiosk-panel summary-report">
            <div className="col-md-12 col-sm-12 col-xs-12 kiosk-fullheight ">
              <div className="title">
                <BackButton onClick={this.handleBackScene}>Back</BackButton>
                Pending
              </div>
              <hr />
              <AssignmentPendingTableContainer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
