import React from 'react'
import styled from 'styled-components'
import AppBar from '../../components/AppBar'
import { I18n } from 'react-redux-i18n'
import CheckInTypePanel from './CheckInTypePanel'
import CheckInTypeResult from './CheckInTypeResult'
import CheckInTypeError from './CheckInTypeError'
import ReservationInfo from './ReservationInfo'
import CheckInFormContainer from './CheckInFormContainer'
import ExtraPrivilegeMenuContainer from './ExtraPrivilegeMenuContainer'

export default ({
  serviceDisplayName,
  serviceName,
  stationName,
  onSubmit,
  onClearForm,
  isSubmitting,
}) => {
  return (
    <div className="service">
      <AppBar
        header={serviceName.toUpperCase()}
        subHeader={`${I18n.t('checkInScene.title')} (${stationName} - ${serviceDisplayName})`}
        hasBtnGroup
      />
      <div className="kiosk-container">
        <div className="kiosk-panel">
          <LeftPanel>
            <CheckInTypePanel onSubmit={onSubmit} />
          </LeftPanel>
          <RightPanel>
            <div className="row">
              <div className="col-xs-4 right-title">
                <CheckInTypeResult />
                <CheckInTypeError />
                <ExtraPrivilegeMenuContainer />
                <ReservationInfo />
              </div>
              <div className="col-xs-8 kiosk-form-container">
                <CheckInFormContainer
                  onSubmit={onSubmit}
                  onClearForm={onClearForm}
                />
                isSubmitting={isSubmitting}
              </div>
            </div>
          </RightPanel>
        </div>
      </div>
    </div>
  )
}

const LeftPanel = styled.div.attrs({
  className: 'col-xs-3 left-panel',
})`
  height: 100%;
`

const RightPanel = styled.div.attrs({
  className: 'col-xs-9 kiosk-fullheight',
})``
