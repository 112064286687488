import React from 'react'
import Select from 'react-select'

export const selectFilterType = (customFilterParameters) => ({
  type: 'CustomFilter',
  getElement: getFilter,
  customFilterParameters,
})

const getFilter = (filterHandler, customFilterParameters) => (
  <SelectFilter
    filterHandler={filterHandler}
    customFilterParameters={customFilterParameters}
  />
)

class SelectFilter extends React.Component {
  state = {
    value: undefined,
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  render() {
    const {
      customFilterParameters: { options },
    } = this.props
    return (
      <Select
        options={options}
        value={this.state.value}
        onChange={this.handleChange}
        simpleValue
        openOnFocus
      />
    )
  }
}
