import React from 'react'
import { SplitView, LeftPanel, RightPanel } from '../../components/SplitView'
import WorkSummaryListViewContainer from '../../components/WorkSummaryListView'
import AssignmentStaffWorkSummaryDetailView from './AssignmentStaffWorkSummaryDetailView'

export default class AssignmentStaffWorkSummarySplitView extends React.Component {
  state = {
    activeWorkSummary: undefined,
  }

  handleWorkSummaryClick = (workSummary) => {
    this.setState({
      activeWorkSummary: workSummary,
    })
  }

  render() {
    return (
      <SplitView>
        <div className="summary-report">
          <LeftPanel>
            <WorkSummaryListViewContainer
              onWorkSummaryClick={this.handleWorkSummaryClick}
              onBack={this.props.onBack}
            />
          </LeftPanel>
          <RightPanel>
            <AssignmentStaffWorkSummaryDetailView
              workSummary={this.state.activeWorkSummary}
            />
          </RightPanel>
        </div>
      </SplitView>
    )
  }
}
