import React from 'react'
import { connect } from 'react-redux'
import { sumBy } from 'lodash'
import Badge from '../../components/Badge'
import { I18n } from 'react-redux-i18n'
import { stateCode, countPax } from '../../utils/serviceTxn'

const withServiceTxns = connect(({ fetch }) => ({
  serviceTxns:
    fetch.serviceTxn && fetch.serviceTxn.list ? fetch.serviceTxn.list : [],
}))

const countPaxFilteredBy = (serviceTxns, filterBy) => {
  const serviceInfos = serviceTxns
    .filter(filterBy)
    .map((serviceTxn) => serviceTxn.serviceInfo)
  return sumBy(serviceInfos, countPax)
}

export const ActiveCustomerAmountBadge = withServiceTxns(({ serviceTxns }) => {
  const amount = countPaxFilteredBy(
    serviceTxns,
    (serviceTxn) => !serviceTxn.serviceInfo || !serviceTxn.serviceInfo.outTime,
  )

  return (
    <Badge
      label={I18n.t('stationReportScene.activeCustomerAmount')}
      data={amount}
      dataStyle="success"
    />
  )
})

export const AlmostTimeoutAmountBadge = withServiceTxns(({ serviceTxns }) => {
  const amount = countPaxFilteredBy(
    serviceTxns,
    (serviceTxn) => stateCode(serviceTxn) === 2,
  )
  return (
    <Badge
      label={I18n.t('stationReportScene.almostTimeoutAmount')}
      data={amount}
      dataStyle="warning"
    />
  )
})

export const TimeoutAmountBadge = withServiceTxns(({ serviceTxns }) => {
  const amount = countPaxFilteredBy(
    serviceTxns,
    (serviceTxn) => stateCode(serviceTxn) === 1,
  )
  return (
    <Badge
      label={I18n.t('stationReportScene.timeoutAmount')}
      data={amount}
      dataStyle="danger"
    />
  )
})
