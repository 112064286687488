import React from 'react'
import ServiceTxnTable from './ServiceTxnTable'
import { escape } from '../../utils/regex'

export default class ServiceTxnTableContainer extends React.Component {
  lastFetchParams = {
    page: 1,
    limit: 50,
    sort: 'serviceInfo.inTime',
    order: 'desc',
  }

  componentDidMount() {
    this.fetchInstances(this.lastFetchParams)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isRefresh) {
      this.fetchInstances(this.lastFetchParams)
      this.props.onRefreshTabComplete()
    }
  }

  fetchInstances = (params) => {
    this.lastFetchParams = params
    this.props.fetchInstances(params)
  }

  handleDataChange = (changes) => {
    this.fetchInstances({
      page: changes.currentPage,
      limit: changes.sizePerPage,
      sort: changes.sort,
      order: changes.order,
      ...this.createQueryFilter(changes.filter),
    })
  }

  createQueryFilter = (filter) => {
    console.log(filter)
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.checkInType) queryfilter.checkInType = filter.checkInType
    if (filter.walkinCheckInType)
      queryfilter['walkinCheckInInfo.type'] = filter.walkinCheckInType
    if (filter.reservationMainPaxName)
      queryfilter.reservationMainPaxName_like = escape(
        filter.reservationMainPaxName,
      )
    if (filter.flightNumber)
      queryfilter.flightNumber_like = escape(filter.flightNumber)
    if (filter.airlineName)
      queryfilter.airlineName_like = escape(filter.airlineName)
    if (filter.agentName) queryfilter.agentName_like = escape(filter.agentName)
    if (filter.cardName) queryfilter.cardName_like = escape(filter.cardName)
    if (filter.isCash) queryfilter.isCash = filter.isCash

    return queryfilter
  }

  render() {
    return (
      <div className="col-md-12">
        <ServiceTxnTable
          {...this.props}
          instances={this.props.instances}
          onDataChange={this.handleDataChange}
          totalCount={this.props.totalCount}
          sizePerPage={50}
        />
      </div>
    )
  }
}
