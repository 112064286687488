import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkIfAnyGrantedByGivenAuth } from '../../modules/authService'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const auth = rest.auth
      const isAuthorized = checkIfAnyGrantedByGivenAuth(auth, rest.ifAnyGranted)
      if (isAuthorized) return <Component {...props} />
      else if (!auth.isFetching && !auth.isPrepared) {
        return null
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    }}
  />
)

export default withRouter(
  connect((state) => ({ auth: state.auth }))(PrivateRoute),
)
