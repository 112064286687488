import React from 'react'

export default ({ card, onClick }) => (
  <div
    className="col-xs-6 text-center btn btn-reservation btn-large"
    onClick={() => onClick(card)}
  >
    <b>{card.name}</b>
  </div>
)
