import React from 'react'
import { ListGroup } from 'react-bootstrap'
import ServiceTxnListViewItem from './ServiceTxnListViewItem'

export default ({ serviceTxns, onServiceTxnSelect }) => (
  <ListGroup>
    {serviceTxns &&
      serviceTxns.map((serviceTxn) => (
        <ServiceTxnListViewItem
          key={serviceTxn._id}
          serviceTxn={serviceTxn}
          onClick={() => onServiceTxnSelect(serviceTxn)}
        />
      ))}
  </ListGroup>
)
