import React from 'react'
import AppBar from '../../components/AppBar'
import storage from '../../storage'
import AssignmentStaffWorkSummarySplitView from './AssignmentStaffWorkSummarySplitView'

export default class AssignmentStaffScene extends React.Component {
  handleBackScene = () => {
    if (this.props.history) this.props.history.goBack()
  }

  render() {
    const { stationName, serviceDisplayName } = storage.info()
    return (
      <div className="service">
        <AppBar
          header="Assignment"
          subHeader={`Staff (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup
        />

        <AssignmentStaffWorkSummarySplitView onBack={this.handleBackScene} />
      </div>
    )
  }
}
