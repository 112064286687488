import React from 'react'
import { Form, ButtonGroup, Button, Row, Col } from 'react-bootstrap'
import { Translate, I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import { createQuery } from '../../modules/product/ProductSearchSelect'
import {
  AutosugguestTextFieldGroup,
  TextFieldGroup,
  DateTimeFieldGroup,
  SelectFieldGroup,
  TextAreaFieldGroup,
  ProductSearchSelectFieldGroup,
} from './CheckInFormFields'

const CheckInForm = ({
  serviceTxn,
  onFieldChange,
  onServiceTxnSelect,
  errors,
  onSubmit,
  clearForm,
  submitted,
  loadServiceTxns,
  travelClassOptions,
  touch,
  pristine,
  checkoutable,
  isCheckedOut,
  openCheckoutModal,
  renderInfo,
  onScan,
  kioskServiceInfo,
  isShowProductSelect,
  cancelable,
  openCancelModal,
  isSubmitting,
}) => (
  <Form className="kiosk-form form-horizontal">
    <Row>
      <BtnGroupKiosk className="col-md-12">
        <ButtonGroup className=" pull-left">
          <div className="btn-group">
            {checkoutable && (
              <Button
                bsSize="large"
                bsStyle="success"
                onClick={openCheckoutModal}
              >
                <Translate value="action.checkout" />
              </Button>
            )}
          </div>
          <div className="btn-group">
            {cancelable && (
              <Button bsSize="large" bsStyle="danger" onClick={openCancelModal}>
                <Translate value="action.cancel" />
              </Button>
            )}
          </div>
        </ButtonGroup>
        <ButtonGroup className="pull-right">
          <Button
            bsSize="large"
            bsStyle="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            <Translate
              value={
                !checkoutable && !isCheckedOut
                  ? 'action.checkin'
                  : 'action.update'
              }
            />
          </Button>
          <Button bsSize="large" onClick={clearForm}>
            <Translate value="actions.clear" />
          </Button>
          <Button bsSize="large" bsStyle="success" onClick={onScan}>
            <Translate value="actions.scan" />
          </Button>
        </ButtonGroup>
      </BtnGroupKiosk>
    </Row>

    {renderInfo && renderInfo()}

    {isShowProductSelect && (
      <Row>
        <Col xs={12}>
          <ProductSearchSelectFieldGroup
            name="productId"
            touchedServiceTxn
            i18n="checkInScene.form.productId"
            value={serviceTxn.productId}
            onChange={onFieldChange}
            error={errors.productId}
            submitted={submitted}
            options={travelClassOptions}
            touched={touch.productId}
            pristine={pristine.productId}
            selectValue={(product) => product._id}
            initialValue={serviceTxn.productId}
            createQuery={(searchText) => ({
              ...createQuery(searchText),
              serviceId: kioskServiceInfo.serviceId,
            })}
          />
        </Col>
      </Row>
    )}

    <Row>
      <Col xs={6}>
        <AutosugguestTextFieldGroup
          name="customerLastName"
          i18n="checkInScene.form.customerLastName"
          value={serviceTxn.customerLastName}
          onChange={onFieldChange}
          error={errors.customerLastName}
          loadSuggestions={loadServiceTxns}
          getSuggestionValue={(serviceTxn) => serviceTxn.customerLastName}
          onSuggestionSelected={onServiceTxnSelect}
          renderSuggestion={renderMainPaxFullNameSuggestion}
          submitted={submitted}
          touched={touch.customerLastName}
          pristine={pristine.customerLastName}
        />
      </Col>
      <Col xs={6}>
        <AutosugguestTextFieldGroup
          name="customerFirstName"
          i18n="checkInScene.form.customerFirstName"
          value={serviceTxn.customerFirstName}
          onChange={onFieldChange}
          error={errors.customerFirstName}
          loadSuggestions={loadServiceTxns}
          getSuggestionValue={(serviceTxn) => serviceTxn.customerFirstName}
          onSuggestionSelected={onServiceTxnSelect}
          renderSuggestion={renderMainPaxFullNameSuggestion}
          submitted={submitted}
          touched={touch.customerFirstName}
          pristine={pristine.customerFirstName}
        />
      </Col>
    </Row>

    <Row>
      <Col xs={5}>
        <TextFieldGroup
          name="flightNumber"
          i18n="checkInScene.form.flightNumber"
          value={serviceTxn.flightNumber}
          onChange={onFieldChange}
          error={errors.flightNumber}
          submitted={submitted}
          touched={touch.flightNumber}
          pristine={pristine.flightNumber}
        />
      </Col>
      <Col xs={3}>
        <TextFieldGroup
          name="seatNumber"
          i18n="checkInScene.form.seatNumber"
          value={serviceTxn.seatNumber}
          onChange={onFieldChange}
          error={errors.seatNumber}
          submitted={submitted}
          touched={touch.seatNumber}
          pristine={pristine.seatNumber}
        />
      </Col>
      <Col xs={4}>
        <SelectFieldGroup
          name="travelClass"
          touchedServiceTxn
          i18n="checkInScene.form.travelClass"
          value={serviceTxn.travelClass}
          onChange={onFieldChange}
          error={errors.travelClass}
          submitted={submitted}
          options={travelClassOptions}
          touched={touch.travelClass}
          pristine={pristine.travelClass}
        />
      </Col>
    </Row>

    <Row>
      <Col xs={6}>
        {/* <DateTimeFieldGroup
            name="serviceInfo.inTime"
            i18n="checkInScene.form.serviceDateTime"
            value={serviceTxn.serviceInfo.inTime}
            onChange={onFieldChange}
            error={errors.serviceInfo.inTime}
            submitted={submitted}
            dateFormat={I18n.t('date.shortDateTimeFormat')}
            showTimeSelect
            timeIntervals={15}
            withPortal
            touched={touch.serviceInfo.inTime}
            pristine={pristine.serviceInfo.inTime} /> */}
        <DateTimeFieldGroup
          name="serviceInfo.inTime"
          i18n="checkInScene.form.serviceDateTime"
          value={serviceTxn.serviceInfo.inTime}
          onChange={onFieldChange}
          error={errors.serviceInfo.inTime}
          submitted={submitted}
          dateFormat={I18n.t('date.shortDateTimeFormat')}
          touched={touch.serviceInfo.inTime}
          pristine={pristine.serviceInfo.inTime}
        />
      </Col>

      <Col xs={6}>
        <DateTimeFieldGroup
          name="serviceInfo.stdTime"
          i18n="checkInScene.form.stdTime"
          value={serviceTxn.serviceInfo.stdTime}
          onChange={onFieldChange}
          error={errors.serviceInfo.stdTime}
          submitted={submitted}
          dateFormat={I18n.t('date.shortDateTimeFormat')}
          touched={touch.serviceInfo.stdTime}
          pristine={pristine.serviceInfo.stdTime}
        />
      </Col>
    </Row>

    <Row>
      <Col xs={3}>
        <TextFieldGroup
          name="serviceInfo.guestPaxAdult"
          i18n="checkInScene.form.guestPaxAdultFull"
          value={serviceTxn.serviceInfo.guestPaxAdult}
          onChange={onFieldChange}
          error={errors.serviceInfo.guestPaxAdult}
          submitted={submitted}
          type="number"
          touched={touch.serviceInfo.guestPaxAdult}
          pristine={pristine.serviceInfo.guestPaxAdult}
        />
      </Col>
      <Col xs={3}>
        <TextFieldGroup
          name="serviceInfo.guestPaxChild"
          i18n="checkInScene.form.guestPaxChildFull"
          value={serviceTxn.serviceInfo.guestPaxChild}
          onChange={onFieldChange}
          type="number"
          error={errors.serviceInfo.guestPaxChild}
          submitted={submitted}
          touched={touch.serviceInfo.guestPaxChild}
          pristine={pristine.serviceInfo.guestPaxChild}
        />
      </Col>
      <Col xs={3}>
        <TextFieldGroup
          name="serviceInfo.guestPaxInfant"
          i18n="checkInScene.form.guestPaxInfantFull"
          value={serviceTxn.serviceInfo.guestPaxInfant}
          onChange={onFieldChange}
          error={errors.serviceInfo.guestPaxInfant}
          submitted={submitted}
          type="number"
          touched={touch.serviceInfo.guestPaxInfant}
          pristine={pristine.serviceInfo.guestPaxInfant}
        />
      </Col>

      <Col xs={3}>
        <TextFieldGroup
          name="serviceInfo.luggageAmount"
          i18n="checkInScene.form.luggageAmount"
          value={serviceTxn.serviceInfo.luggageAmount}
          onChange={onFieldChange}
          error={errors.serviceInfo.luggageAmount}
          submitted={submitted}
          type="number"
          touched={touch.serviceInfo.luggageAmount}
          pristine={pristine.serviceInfo.luggageAmount}
        />
      </Col>
    </Row>

    <Row>
      <Col xs={6}>
        <TextFieldGroup
          name="reference1"
          i18n="checkInScene.form.reference1"
          value={serviceTxn.reference1}
          onChange={onFieldChange}
          error={errors.reference1}
          submitted={submitted}
          touched={touch.reference1}
          pristine={pristine.reference1}
        />
      </Col>
      <Col xs={6}>
        <TextFieldGroup
          name="reference2"
          i18n="checkInScene.form.reference2"
          value={serviceTxn.reference2}
          onChange={onFieldChange}
          error={errors.reference2}
          submitted={submitted}
          touched={touch.reference2}
          pristine={pristine.reference2}
        />
      </Col>
    </Row>

    <Row>
      <Col xs={12}>
        <TextAreaFieldGroup
          name="remark"
          i18n="checkInScene.form.remark"
          value={serviceTxn.remark}
          onChange={onFieldChange}
          error={errors.remark}
          submitted={submitted}
          touched={touch.remark}
          pristine={pristine.remark}
        />
      </Col>
      <Col xs={12}>
        <TextAreaFieldGroup
          name="serviceInfo.note"
          i18n="checkInScene.form.note"
          value={serviceTxn.serviceInfo.note}
          onChange={onFieldChange}
          error={errors.serviceInfo.note}
          submitted={submitted}
          touched={touch.serviceInfo.note}
          pristine={pristine.serviceInfo.note}
        />
      </Col>
    </Row>
  </Form>
)

const BtnGroupKiosk = styled.div`
  margin-bottom: 15px;
`

const renderMainPaxFullNameSuggestion = (serviceTxn) => (
  <div>
    <div>{serviceTxn.reservation.confirmationNumber}</div>
    <div>{serviceTxn.customerName}</div>
  </div>
)

export default CheckInForm
