import React from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { compose } from 'recompose'

import CheckInForm from './CheckInForm'
import { fetchServiceTxns } from '../../api'
import {
  updateCheckInServiceTxnField,
  updateServiceInfo,
  runCodeScanInfo,
  clearCheckIn,
} from '../../actions/checkin'
import { info } from '../../storage'
import { withCheckInType } from './CheckInTypeContainer'
import CheckoutModal from '../../components/CheckoutModal'
import CheckInFormInfoContainer from './CheckInFormInfoContainer'
import { getIssuedOutTime, isCancelable } from '../../utils/serviceTxn'
import { startScan, stopScan } from '../../utils/codeScan'
import { withScanCodeModal } from '../../components/ScanCodeModal'
import parseBarcode from '../../modules/barcodeParser'
import { createItem } from '../../actions/remote_api'
import { ServiceTxnCancelConfirmModal } from '../../components/ServiceTxnCancelConfirmModal'
import { I18n } from 'react-redux-i18n'
import { isProductRequired } from '../../modules/productPrice'

class CheckInFormContainer extends React.Component {
  state = {
    isCheckoutModalOpen: false,
    serviceTxnToCheckout: undefined,
  }

  loadServiceTxns = (searchWord) => {
    if (!searchWord || searchWord.length < 3) {
      return []
    }
    return fetchServiceTxns({
      q: searchWord,
      'service._id': info().serviceId,
      operationDaily: true,
      noCancel: true,
    })
  }

  travelClassOptions = [
    { label: 'Economy', value: 'Economy' },
    { label: 'Business', value: 'Business' },
    { label: 'First Class', value: 'First Class' },
  ]

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  handleServiceTxnSelect = (serviceTxn) => {
    this.props.loadServiceTxn(serviceTxn)
  }

  openCheckoutModal = () => {
    this.setState({
      ...this.state,
      isCheckoutModalOpen: true,
    })
  }

  handleCheckOut = (serviceTxn, outTime) => {
    this.props.updateCheckInServiceTxnField('serviceInfo.outTime', outTime)
    this.closeCheckoutModal()
    this.props.onSubmit()
  }

  closeCheckoutModal = () => {
    this.setState({
      ...this.state,
      isCheckoutModalOpen: false,
    })
  }

  renderInfo = () => {
    if (!this.props.checkoutable) return null

    return (
      <CheckInFormInfoContainer
        inTime={this.props.serviceTxn.serviceInfo.inTime}
        limitTime={getIssuedOutTime(this.props.serviceTxn)}
        outTime={this.props.serviceTxn.serviceInfo.outTime}
      />
    )
  }

  handleFieldChange = (keyPath, value) => {
    this.props.updateCheckInServiceTxnField(keyPath, value)
    const { serviceName, serviceClass } = info()
    if (keyPath === 'travelClass')
      this.props.updateServiceInfo(
        serviceName,
        serviceClass,
        this.props.defaultDurations,
      )
  }

  handleStartScan = () => {
    startScan(this.handleScanSucceed, this.handleScanCanceled)
    this.props.showScanCodeModal()
  }

  handleStopScan = () => {
    stopScan()
  }

  handleScanSucceed = (code) => {
    this.props.createItem({ key: 'barcodeScan', value: code })
    stopScan()
    this.props.hideScanCodeModal()
    if (!code) {
      toastr.error('Error', 'QR Code Data is not found')
      return
    }

    const codeInfo = parseBarcode(code)
    this.props.runCodeScanInfo(
      codeInfo,
      { ...info(), defaultDurations: this.props.defaultDurations },
      this.createHandleScanError(code),
    )
  }

  handleScanCanceled = () => {
    this.props.hideScanCodeModal()
  }

  createHandleScanError = (code) => (error) => {
    var errorMessage = ''

    switch (error.code) {
      case 'airlineCode.notFound':
        errorMessage = 'Airline code is not found in this information'
        break
      case 'airline.notFound':
        errorMessage = `Airline is not found for given ${error.airlineCode}`
        break
      case 'context.notSupported':
        errorMessage =
          "Code information's context is not supported (Please check your keyboard layout.)"
        break
      case 'confirmation.notFound':
        errorMessage = 'Confirmation Number is not found in information'
        break
      case 'reservation.notFound':
        errorMessage = `Reservation is not found for given ${
          error.confirmationNumber
        }`
        break

      default:
        errorMessage = 'Unknown Error!'
        break
    }

    toastr.error('Error', errorMessage)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isScanCodeModalShow && !nextProps.isScanCodeModalShow) {
      this.handleStopScan()
    }
  }

  isCheckedOut = () => {
    return this.props.serviceTxn.serviceInfo.outTime !== undefined
  }

  isShowProductSelect = () => {
    return isProductRequired(this.props.serviceTxn)
  }

  serviceTxnDidConfirm = () => {
    this.props.clearCheckIn()
    toastr.success(I18n.t('cancelServiceTxnModal.toast'))
  }

  render() {
    return (
      <div>
        <ServiceTxnCancelConfirmModal
          serviceTxn={this.props.serviceTxn}
          serviceTxnDidConfirm={this.serviceTxnDidConfirm}
        >
          {(showModal) => (
            <CheckInForm
              serviceTxn={this.props.serviceTxn}
              errors={this.props.errors}
              onFieldChange={this.handleFieldChange}
              onServiceTxnSelect={this.handleServiceTxnSelect}
              loadServiceTxns={this.loadServiceTxns}
              travelClassOptions={this.travelClassOptions}
              onSubmit={this.handleSubmit}
              clearForm={this.props.onClearForm}
              submitted={this.props.submitted}
              touch={this.props.touch}
              pristine={this.props.pristine}
              checkoutable={this.props.checkoutable}
              isCheckedOut={this.isCheckedOut()}
              openCheckoutModal={this.openCheckoutModal}
              renderInfo={this.renderInfo}
              onScan={this.handleStartScan}
              kioskServiceInfo={info()}
              isShowProductSelect={this.isShowProductSelect()}
              cancelable={isCancelable(this.props.serviceTxn)}
              openCancelModal={showModal}
              isSubmitting={this.props.isSubmitting}
            />
          )}
        </ServiceTxnCancelConfirmModal>

        {this.props.checkoutable && (
          <CheckoutModal
            isShow={this.state.isCheckoutModalOpen}
            onHide={this.closeCheckoutModal}
            serviceTxn={this.props.serviceTxn}
            onCheckOut={this.handleCheckOut}
          />
        )}
      </div>
    )
  }
}

const reduxConnect = connect(
  ({ checkin, fetch }) => ({
    serviceTxn: checkin.serviceTxn,
    errors: checkin.errors,
    submitted: checkin.submitted,
    touch: checkin.touch,
    pristine: checkin.pristine,
    isCreate: checkin.isCreate,
    checkoutable: checkin.checkoutable,
    defaultDurations:
      fetch.defaults && fetch.defaults.list
        ? fetch.defaults.list.durations
        : undefined,
  }),
  {
    updateCheckInServiceTxnField,
    updateServiceInfo,
    runCodeScanInfo,
    createItem,
    clearCheckIn,
  },
)

const enhancer = compose(reduxConnect, withCheckInType, withScanCodeModal)

export default enhancer(CheckInFormContainer)
