import React from 'react'
import ReservationTable from './ReservationTable'
import { escape } from '../../utils/regex'

export default class ReservationTableContainer extends React.Component {
  lastFetchParams = {
    page: 1,
    limit: 50,
    sort: 'serviceDateTime',
    order: 'desc',
  }

  componentDidMount() {
    this.fetchInstances(this.lastFetchParams)
  }

  componentWillUnmount() {
    this.props.clearInstances()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isRefresh) {
      this.fetchInstances(this.lastFetchParams)
      this.props.onRefreshTabComplete()
    }
  }

  fetchInstances = (params) => {
    this.lastFetchParams = params
    this.props.fetchInstances(params)
  }

  handleDataChange = (changes) => {
    this.fetchInstances({
      page: changes.currentPage,
      limit: changes.sizePerPage,
      sort: changes.sort,
      order: changes.order,
      ...this.createQueryFilter(changes.filter),
    })
  }

  renderButtonGroup = () => {
    return <div></div>
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.mainPaxName)
      queryfilter.mainPaxName_like = escape(filter.mainPaxName)
    if (filter.productName)
      queryfilter.productName_like = escape(filter.productName)
    if (filter.status) queryfilter.status = filter.status
    if (filter.agentName) queryfilter.agentName_like = escape(filter.agentName)
    if (filter.cardName) queryfilter.cardName_like = escape(filter.cardName)
    if (filter.isCash) queryfilter.isCash = filter.isCash

    return queryfilter
  }

  render() {
    return (
      <ReservationTable
        {...this.props}
        instances={this.props.instances}
        onDataChange={this.handleDataChange}
        totalCount={this.props.totalCount}
        btnGroup={this.renderButtonGroup}
        settings={{ selectRow: undefined }}
        sizePerPage={50}
      />
    )
  }
}
