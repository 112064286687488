import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import TimeKeeper from 'react-timekeeper'

export default class MultiCheckOutModal extends React.Component {
  state = {
    outTime: moment(),
  }

  handleOutTimeChange = (outTime) => {
    this.setState({ outTime })
  }

  handleCheckOut = () => {
    const { serviceTxnsToCheckout, onCheckOut } = this.props
    onCheckOut(serviceTxnsToCheckout, this.state.outTime)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isShow !== this.props.isShow) {
      this.setState({ outTime: moment() })
    }
  }

  render() {
    const { isShow, onHide, serviceTxnsToCheckout } = this.props
    const label = I18n.t('stationReportScene.checkOutModal')
    return (
      <Modal show={isShow} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Multi Checkout</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderModalBody(
            serviceTxnsToCheckout,
            label,
            this.state.outTime,
            this.handleOutTimeChange,
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button bsSize="lg" onClick={onHide}>
            {I18n.t('action.cancel')}
          </Button>
          <Button bsSize="lg" onClick={this.handleCheckOut} bsStyle="primary">
            {I18n.t('action.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const renderModalBody = (
  serviceTxnsToCheckout,
  label,
  outTime,
  onOutTimeChange,
) => {
  if (serviceTxnsToCheckout) {
    const passengerAmount = serviceTxnsToCheckout.length
    const isPassengerPural = passengerAmount > 1
    return (
      <dl className="dl-horizontal" style={{ fontSize: 18 }}>
        <dt>Checkout for</dt>
        <dd style={{ color: 'black' }}>
          {serviceTxnsToCheckout.length} passenger{isPassengerPural && 's'}
        </dd>
        <dt>{label.outTime}</dt>
        <dd>
          <MomentTimeKeeper value={outTime} onChange={onOutTimeChange} />
        </dd>
      </dl>
    )
  }
  return null
}

const MomentTimeKeeper = ({ value, onChange, ...props }) => (
  <TimeKeeper
    {...props}
    time={momentToTime(value)}
    onChange={createOnTimeChangeHandler(onChange)}
  />
)

const momentToTime = (momentDate) =>
  momentDate && moment.isMoment(momentDate)
    ? momentDate.format('hh:mm a')
    : null

const createOnTimeChangeHandler = (onChange) => (time) => {
  onChange(time ? moment(time.formatted, 'hh:mm a') : null)
}
