import _ from 'lodash'

function isOnSitePayment(serviceTxn) {
  return (
    serviceTxn.checkInType === 'walkin' &&
    ['cash', 'creditCard', 'others'].some(
      (type) => type === _.get(serviceTxn, 'walkinCheckInInfo.type'),
    )
  )
}

export function isProductRequired(serviceTxn) {
  return (
    serviceTxn.checkInType === 'airline' ||
    (serviceTxn.checkInType === 'walkin' &&
      serviceTxn.walkinCheckInInfo.type === 'card')
  )
}

export function shouldUpateProductPrice(serviceTxn) {
  if (!serviceTxn.productId) return false

  if (isProductRequired(serviceTxn)) return true
  else if (serviceTxn.checkInType === 'walkin')
    return isOnSitePayment(serviceTxn)
  return false
}

export function validateProductPrice(serviceTxn) {
  if (isProductRequired(serviceTxn)) {
    return _.isNil(
      _.get(serviceTxn, 'serviceInfo.productPrice.pricingContract'),
    ) || _.isNil(_.get(serviceTxn, 'serviceInfo.productPrice.price'))
      ? 'No pricing contract found on selected Airline/Card for this product'
      : undefined
  } else if (!isOnSitePayment(serviceTxn)) {
    return _.isNil(_.get(serviceTxn, 'serviceInfo.productPrice.price'))
      ? 'No pricing walkin price found on product'
      : undefined
  }

  return undefined
}
