import types from '../constants'
import { createInitialState, setObjectValueAtPath, isCreate } from './checkin'
import { parseFieldToMomentIfNeeded } from './parser'
import { get } from 'lodash'

export default (state, action) => {
  switch (action.type) {
    case types.UPDATE_CHECKIN_SERVICETXN_FIELD:
      return setObjectValueAtPath(state, action.keyPath, action.value)
    case types.LOAD_CHECKIN_WITH_AGENT:
      const { reservation } = action
      const mainPax = reservation.mainPax || {}

      return {
        ...createInitialState().serviceTxn,
        reservationId: reservation.id,
        reservation,
        customerFirstName: mainPax.firstName || '',
        customerLastName: mainPax.lastName || '',
        flightNumber: reservation.flightNumber || '',
        checkInType: 'agent',
        agentCheckInInfo: { agent: reservation.agent },
      }
    case types.LOAD_CHECKIN_SERVICETXN:
      return {
        ...createInitialState().serviceTxn,
        ...parseFieldToMomentIfNeeded(action.serviceTxn),
        reservation: {
          ...createInitialState().serviceTxn.reservation,
          ...parseFieldToMomentIfNeeded(action.serviceTxn.reservation),
        },
      }
    case types.SELECT_CHECKIN_TYPE:
      return {
        ...createInitialState().serviceTxn,
        ...(!isCreate(state) ? state : {}),
        airlineCheckInInfo: undefined,
        agentCheckInInfo: undefined,
        walkinCheckInInfo: undefined,
        ...action.checkInInfo,
      }
    case types.FETCH_CHECKIN_SUCCESS:
      return {
        ...createInitialState().serviceTxn,
        ...parseFieldToMomentIfNeeded(action.data),
      }
    case types.UPDATE_CHECKIN_SERVICETXN_SERVICEINFO:
      if (state.extraPrivileges)
        // related to checkin.spec.js:1056
        return state

      return {
        ...state,
        serviceInfo: serviceInfo(state, action),
      }
    case types.USE_VOUCHER_PRIVILEGE:
    case types.USE_COMPLIMENTARY_PRIVILEGE:
      const extraPrivileges = getExtraPrivilegeType(action)
      return {
        ...state,
        extraPrivileges,
        serviceInfo: {
          ...state.serviceInfo,
          duration: action.duration,
        },
      }
    case types.USE_GUEST_PRIVILEGE:
      const { serviceTxn } = action
      return {
        ...state,
        extraPrivileges: 'guest',
        guestOfId: serviceTxn._id,
        guestOf: serviceTxn,
        serviceInfo: {
          ...state.serviceInfo,
          duration: get(serviceTxn.serviceInfo, 'duration'),
        },
        checkInType: serviceTxn.checkInType,
        airlineCheckInInfo: serviceTxn.airlineCheckInInfo,
        agentCheckInInfo: serviceTxn.agentCheckInInfo,
        walkinCheckInInfo: serviceTxn.walkinCheckInInfo,
      }
    case types.CLEAR_EXTRA_PRIVILEGE:
      return {
        ...state,
        extraPrivileges: undefined,
        guestOfId: undefined,
        guestOf: undefined,
        serviceInfo: {
          ...state.serviceInfo,
          duration: undefined,
        },
      }
    case types.UPDATE_PRODUCT_PRICE:
      return {
        ...state,
        serviceInfo: {
          ...state.serviceInfo,
          productPrice: get(action, 'productPriceData.priceInfo', undefined),
        },
      }
    case types.USE_CODE_SCAN_INFO:
      return state
    default:
      return state
  }
}

const serviceInfo = (serviceTxn, action) => {
  const serviceInfo = {
    ...serviceTxn.serviceInfo,
    duration: undefined,
  }
  switch (serviceTxn.checkInType) {
    case 'walkin':
      const { type, card } = serviceTxn.walkinCheckInInfo
      if (type === 'card' && card.privilegeClass) {
        const privilegeClassToServiceMapping = findClassToServiceMapping(
          card.classToServiceMapping || action.classToServiceMapping,
          action.serviceName,
          action.serviceClass,
          card.privilegeClass,
        )
        if (privilegeClassToServiceMapping)
          serviceInfo.duration = privilegeClassToServiceMapping.duration
      } else if (action.defaultDuration && action.defaultDuration[type]) {
        serviceInfo.duration =
          action.defaultDuration[type][
            `${action.serviceClass}_${action.serviceName}`
          ]
      }
      break

    case 'airline':
      const { airline } = serviceTxn.airlineCheckInInfo
      const privilegeClassToServiceMapping = findClassToServiceMapping(
        airline.classToServiceMapping || action.classToServiceMapping,
        action.serviceName,
        action.serviceClass,
        serviceTxn.travelClass,
      )
      if (privilegeClassToServiceMapping)
        serviceInfo.duration = privilegeClassToServiceMapping.duration
      break
    case 'agent':
      if (action.defaultDuration && action.defaultDuration.agent)
        serviceInfo.duration =
          action.defaultDuration.agent[
            `${action.serviceClass}_${action.serviceName}`
          ]
      break
    default:
      break
  }
  return serviceInfo
}

const findClassToServiceMapping = (
  classToServiceMapping,
  service,
  serviceClass,
  privilegeClass,
) => {
  if (!classToServiceMapping || !service || !serviceClass || !privilegeClass)
    return undefined
  return classToServiceMapping.find(
    (c) =>
      c.privilegeClass === privilegeClass &&
      c.service === service &&
      c.serviceClass === serviceClass,
  )
}

const getExtraPrivilegeType = (action) => {
  if (action.type === types.USE_VOUCHER_PRIVILEGE) return 'voucher'
  else if (action.type === types.USE_COMPLIMENTARY_PRIVILEGE)
    return 'complimentary'
  return undefined
}
