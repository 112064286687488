import React from 'react'
import AppBar from '../../components/AppBar'
import MarkFinalResultModalContainer from './MarkFinalResultModalContainer'
import Background from '../../../src/img/bg.jpg'

class MarkFinalServiceTxnScene extends React.Component {
  state = {
    type: undefined,
    isShow: false,
  }

  handleShowModal = (type) => {
    this.setState((state) => ({
      isShow: true,
      type,
    }))
  }

  handleHide = () => {
    this.setState((state) => ({
      isShow: false,
    }))
  }

  handleStartScanCompleted = () => {
    this.handleShowModal('completed')
  }

  handleStartScanNoShow = () => {
    this.handleShowModal('noShow')
  }

  handleStartScanMissedCase = () => {
    this.handleShowModal('missedCase')
  }

  render() {
    return (
      <div className="service">
        <AppBar
          header="Quick Check"
          subHeader="update status of service transaction to final status"
          hasBtnGroup
        />

        <div className="kiosk-container">
          <div className="summaryReport-container">
            <a onClick={this.handleStartScanCompleted}>
              <div className="summaryReport">
                <div
                  className="imageDisplay"
                  style={{ backgroundImage: `url(${Background})` }}
                ></div>
                <div className="title">
                  <span>Completed</span>
                </div>
              </div>
            </a>

            <div className="summaryReport" onClick={this.handleStartScanNoShow}>
              <div
                className="imageDisplay"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="title">
                <span>No Show</span>
              </div>
            </div>

            <div
              className="summaryReport"
              onClick={this.handleStartScanMissedCase}
            >
              <div
                className="imageDisplay"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="title">
                <span>Missed Case</span>
              </div>
            </div>
          </div>
        </div>

        <MarkFinalResultModalContainer
          type={this.state.type}
          isShow={this.state.isShow}
          onHide={this.handleHide}
        />
      </div>
    )
  }
}

export default MarkFinalServiceTxnScene
